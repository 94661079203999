import { ReactElement, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { AuthContext } from '@context/AuthContext';

import Stl from '@root/lib/Stl';
import accountsignup from './AccountSignup.module.scss';
const st = (new Stl()).bind(accountsignup);

type Props = {
  setCurrentStep: UnknownFunc;
}

export default function AccountSignupFormVerificationCheck(props: Props): ReactElement {
  const router = useRouter();
  const auth = useContext(AuthContext);
  const [ title, setTitle ] = useState(null);
  const [ message, setMessage ] = useState(null);

  useEffect(() => {
    console.log(router);

    auth.verifyRequest({
      variables: {
        token: router.query.code,
      },
    })
      .then(success => {
        console.log(success);
        props.setCurrentStep('signup-verification-success');
      })
      .catch(error => {
        setTitle('Verification failed');
        setMessage('Invalid verification code or link has expired.');
        console.log(error);
      });
  }, []);

  return (
    <div>
      <h4 className={ st`modalTitle` }>{ title || 'Hold on!' }</h4>
      <p className={ st`modalTextCentered` }>{ message || 'Verifying your e-mail address...' }</p>
    </div>
  );
}
