import { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import FormsAccountSignin from '../Form/AccountSignin/AccountSignin';
import { AuthContext } from '@context/AuthContext';
import { ModalsContext } from '@root/context/ModalsContext';

import {
  FormsAccountSignup,
  FormsAccountSignupEmail,
  FormsAccountSignupVerification,
  FormsAccountSignupVerificationCheck,
  FormsAccountSignupVerificationSuccess,
} from '../Form/AccountSignup';

import { FormsPasswordReset, FormsPasswordResetRequest } from '../Form/PasswordReset';

import FormPasswordUpdate  from '../Form/PasswordUpdate/PasswordUpdate';

import Stl from '@root/lib/Stl';
import signin from './Signin.module.scss';
const st = (new Stl()).bind(signin);

type Props = {
  modalVisible: boolean;
  setModalVisible: BoolVoidFunc;
  currentStep?: string;
}

export default function SigninModal(props: Props): ReactElement {
  const modalsContext = useContext(ModalsContext);
  const auth = useContext(AuthContext);
  const modalRef = useRef();
  const router = useRouter();
  const [ currentStep, setCurrentStep ] = useState(props.currentStep || 'login');
  const [ stepFromUpdate, setStepFromUpdate ] = useState(false);

  // Keep track of the sign in state
  useEffect(() => {
    if (auth.signedIn) {
      modalsContext.setModalVisibleSignin(false);

      if (props.currentStep === 'login') {
        // Redirect after sign in
        if (router.query.redirectUrl) {
          router.push(router.query.redirectUrl as string);
        } else {
          // Close the sign in modal automatically after sign in
          // router.push(originalRoute, originalRoute, { shallow: true });

          // Redirect to listings or bookings if not on listing detail page
          if (router.pathname !== '/listings/[uuid]') {
            if (auth.account?.isHost) {
              router.push('/dashboard/listings');
            } else {
              router.push('/dashboard/bookings');
            }
          }
        }
      }
    }
  }, [ auth.signedIn ]);

  useEffect(() => {
    if (props.currentStep && currentStep !== props.currentStep) {
      setCurrentStep(props.currentStep);
    }

    if (props.currentStep === 'login') {
      setStepFromUpdate(false);
    } else if (props.currentStep === 'update') {
      setStepFromUpdate(true);
    }
  }, [ props.currentStep ]);

  const closeModal = () => {
    modalsContext.setModalVisibleSignin(false);

    // Changing modal after hide animation
    setTimeout(() => {
      if(currentStep === 'reset-request') {
        !stepFromUpdate ? setCurrentStep('login') : setCurrentStep('update');
      }
    }, 400);

    // Remove query params from URL
    if (router.asPath.indexOf('?') > -1) {
      router.replace(router.asPath.split('?')[0], undefined, { shallow: true });
    }

    if (router.asPath === '/createaccount') {
      router.replace('/', undefined, { shallow: true });
    }
  };

  return (
    <div
      ref={ modalRef }
      onClick={ event => {
        if (event.target === modalRef.current) {
          closeModal();
        }
      } }
      className={ st`modalContainer ${ props.modalVisible && 'modalContainerVisible' }` }
    >
      <div className={ st`modal` }>
        { router.asPath !== '/createaccount' &&
          <button
            onClick={ closeModal }
            className={ st`modalClose` }
          >
            <img src="/images/icons/close-alt.svg" />
          </button>
        }

        { currentStep === 'login' &&
          <FormsAccountSignin
            status={ auth.signInStatus }
            message={ auth.signInErrorMessage }
            onValidated={ auth.signIn }
            setCurrentStep={ setCurrentStep }
            setStepFromUpdate={ setStepFromUpdate }
          />
        }

        { currentStep === 'update' &&
          <FormPasswordUpdate
            status=""
            onValidated={ formData => (formData) }
            setCurrentStep={ setCurrentStep }
            setStepFromUpdate= { setStepFromUpdate }
            closeModal={ closeModal }
          />
        }

        { currentStep === 'reset' &&
          <FormsPasswordReset
            status=""
            onValidated={ formData => (formData) }
            setCurrentStep={ setCurrentStep }
            onClose ={ closeModal }
          />
        }

        { currentStep === 'reset-request' &&
          <FormsPasswordResetRequest
            status=""
            onValidated={ formData => (formData) }
            setCurrentStep={ setCurrentStep }
            stepFromUpdate= { stepFromUpdate }
          />
        }

        {/* { currentStep === 'signup' &&
          <FormsAccountSignup
            status=""
            onValidated={ formData => (formData) }
            setCurrentStep={ setCurrentStep }
          />
        } */}

        { currentStep === 'signup' &&
          <FormsAccountSignupEmail
            status={ auth.signUpStatus }
            message={ auth.signUpErrorMessage }
            onValidated={ auth.signUp }
            setCurrentStep={ setCurrentStep }
          />
        }

        { currentStep === 'signup-verification' &&
          <FormsAccountSignupVerification
            setCurrentStep={ setCurrentStep }
          />
        }

        { currentStep === 'verify' &&
          <FormsAccountSignupVerificationCheck
            setCurrentStep={ setCurrentStep }
          />
        }

        { currentStep === 'signup-verification-success' &&
          <FormsAccountSignupVerificationSuccess
            setCurrentStep={ setCurrentStep }
          />
        }
      </div>
    </div>
  );
}
