import Link from 'next/link';
import { ReactElement } from 'react';
import Stl from '@root/lib/Stl';
import { RevenueCalculator } from '../../Website';

import banner from './Banner.module.scss';
const st = (new Stl()).bind(banner);

type Props = {
  variant?: 'primary' | 'secondary' | 'tertiary';
  background?: string;
  backgroundUrl?: string;
  backgroundUrl2x?: string;
  heading: ReactElement;
  description?: ReactElement;
  media?: string;
  media2x?: string;
  button?: { href?: string, variant?: string, label?: string, onClick?: UnknownFunc };
  calculator?: boolean;
};

export default function Banner(props: Props): ReactElement {
  return (
    <div className={ st`${ props.variant ? props.variant : 'primary' }` }>
      <div className={ st`background` }>
        { props.backgroundUrl &&
          <div className={ st`image-wrapper` }>
            <img
              src={ props.backgroundUrl }
              srcSet={ `${ props.backgroundUrl } 1x, ${ props.backgroundUrl2x } 2x` }
            />
          </div>
        }
      </div>

      <div className={ st`container` }>
        <div className={ st`grid` }>
          <div className={ st`heading-container` }>
            <h1 className={ st`heading` }>{ props.heading }</h1>
            { props.description &&
              <h4 className={ st`description` }>{ props.description }</h4>
            }

            { props.button &&
              <div className={ st`description-container` }>

                {props.button && props.button.href &&
                  <Link href={ props.button.href }>
                    <a className={ `button button--${ props.button.variant }` } onClick={ () => props.button.onClick() }>
                      {props.button.label}
                    </a>
                  </Link>
                }

                {props.button && !props.button.href &&
                  <a className={ `button button--${ props.button.variant }` } onClick={ () => props.button.onClick() }>
                    {props.button.label}
                  </a>
                }
              </div>
            }
          </div>

          { props.calculator &&
            <RevenueCalculator />
          }

          { props.media &&
            <div className={ st`media` }>
              <div className={ st`image` }>
                <img src={ props.media } srcSet={ `${ props.media } 1x, ${ props.media2x } 2x` } width="672px" height="480px" />
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}
