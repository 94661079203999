import { useMemo } from 'react';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from 'apollo-link-error';

let apolloClient;

function createApolloClient() {
  // if (!process.browser) {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: errorLink.concat(new HttpLink({
      uri: process.env.NEXT_PUBLIC_HOST_GRAPHQL,
    })),
    cache: new InMemoryCache(),
  });
  // }

  // return new ApolloClient({
  //   ssrMode: typeof window === 'undefined',
  //   link: errorLink.concat(new WebSocketLink({
  //     uri: 'ws://localhost:8080/v1/graphql',
  //     options: {
  //       reconnect: true,
  //     },
  //   })),
  //   cache: new InMemoryCache(),
  // });
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, extensions }) => {
      console.log(
        `[GraphQL error]: Message: ${ message }, Location: ${ extensions.code }`
      );
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${ networkError }`);
  }
});

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}

export function useApollo(initialState) {
  const store = useMemo(() => initializeApollo(initialState), [ initialState ]);
  return store;
}
