import { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { Button } from '../..';

import Stl from '@root/lib/Stl';
import accountsignup from './AccountSignup.module.scss';
const st = (new Stl()).bind(accountsignup);

type Props = {
  setCurrentStep: UnknownFunc;
}

export default function AccountSignupFormVerificationSuccess(props: Props): ReactElement {
  const router = useRouter();

  return (
    <div>
      <div className={ st`modalHeader` }>
        <img className={ st`modalHeaderBackground` } src="/images/modal-header.svg" />
        <img className={ st`modalHeaderIcon` } src="/images/modal-checkmark.svg" />
      </div>
      <h4 className={ st`modalTitle` }>Welcome to YourDesq</h4>
      <p className={ st`modalTextCentered` }>Your email address has successfully been verified.</p>
      <div className={ st`formRow formFooter` }>
        <button
          className={ st`button button--primary buttonWide` }
          onClick={ () => props.setCurrentStep('login') }
        >
          Go to login
        </button>
      </div>
      <div className={ st`formRow formFooter` }>
        <Button variant="text" onClick={ () => router.push('/') }>Go to homepage</Button>
      </div>
    </div>
  );
}
