import { ReactElement, useCallback, useState } from 'react';
import { Button } from '../..';

import Stl from '@root/lib/Stl';
import accountsignin from './AccountSignin.module.scss';
const st = (new Stl()).bind(accountsignin);

type Props = {
  status: number;
  message: string;
  onValidated: UnknownFunc;
  setCurrentStep: UnknownFunc;
  setStepFromUpdate: UnknownFunc;
}

export default function AccountSignupForm(props: Props): ReactElement {
  // Create reference variables for input fields
  const [ email, setEmail ] = useState<string>('');
  const [ password, setPassword ] = useState<string>('');

  const [ modalTitle, setModalTitle ] = useState<string>(null);
  const [ modalText, setModalText ] = useState<string>(null);

  const [ errorMessage, setErrorMessage ] = useState(null);
  const [ successMessage, setSuccessMessage ] = useState(null);

  if (props.status === -1 && props.message && props.message !== errorMessage) {
    setErrorMessage(props.message);
  }

  const submitForm = useCallback((event) => {
    event.preventDefault();

    setErrorMessage(null);
    setSuccessMessage(null);

    props.onValidated({
      email,
      password,
    });
  }, [ email, password ]);

  return (
    <form onSubmit={ submitForm }>
      <h4 className={ st`modalTitleSecondary` }>{ modalTitle || 'Log in to YourDesq' }</h4>

      {/* <button
        className={ st`button button--ghost buttonWide` }
        onClick={ () => console.log('Log in with Google') }
      >
        <img src="/images/icons/google.svg" />
        <span>Log in with Google</span>
      </button>

      <div className={ st`divider` }><span>OR</span></div> */}

      { props.status !== 1 &&
        <>
          <div className={ st`formRow` }>
            <label className={ st`formRowLabel` } htmlFor="userEmail">Email address</label>
            <input
              className={ st`formInput` }
              type="email"
              placeholder="Your email address"
              name="EMAIL"
              id="userEmail"
              value={ email }
              onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value) }
            />
          </div>
          <div className={ st`formRow` }>
            <label className={ st`formRowLabel` } htmlFor="userPassword">
              <span>Password</span>
              <a onClick={ () => { props.setStepFromUpdate(false); props.setCurrentStep('reset-request'); } }>Forgot your password?</a>
            </label>
            <input
              className={ st`formInput` }
              type="password"
              placeholder="Your password"
              name="LNAME"
              id="userPassword"
              value={ password }
              onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value) }
            />
          </div>

          <div className={ st`formRow formRowAction` }>
            <div>
              { errorMessage &&
                <span className={ st`errorMessage` } dangerouslySetInnerHTML={ { __html: errorMessage } } />
              }
              { successMessage &&
                <span className={ st`successMessage` } dangerouslySetInnerHTML={ { __html: successMessage } } />
              }
            </div>

            <button
              type="submit"
              className={ st`button button--primary buttonWide` }
              onClick={ submitForm }
            >
              Log in
            </button>
          </div>

          <div className={ st`formRow formFooter` }>
            <span>Don’t have an account yet? <Button variant="text" onClick={ () => props.setCurrentStep('signup') }>Sign up</Button></span>
          </div>
        </>
      }

      { props.status === 1 &&
        <div className={ st`formRow formRowLast` }>
          <div></div>
        </div>
      }
    </form>
  );
}
