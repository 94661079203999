import { ReactElement } from 'react';
import Stl from '@root/lib/Stl';
import logos from './Logos.module.scss';
const st = (new Stl()).bind(logos);

export default function Logos(): ReactElement {
  return (
    <div className={ st`primary` }>
      <div className={ st`container` }>
        <div className={ st`heading` }>Trusted by</div>
        <div className="image">
          <img src="/images/logos-strakzat.svg" />
        </div>
        <div className="image">
          <img src="/images/logos-dott.svg" />
        </div>
        <div className="image">
          <img src="/images/logos-strakzat.svg" />
        </div>
        <div className="image">
          <img src="/images/logos-microsoft.svg" />
        </div>
        <div className="image">
          <img src="/images/logos-strakzat.svg" />
        </div>
      </div>
    </div>
  );
}
