import { ReactElement, useContext } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

import { AuthContext } from '@context/AuthContext';

import Stl from '@root/lib/Stl';
import navigation from './Navigation.module.scss';
const st = (new Stl()).bind(navigation);

type Props = {
  visible: boolean;
}

export default function Navigation(props: Props): ReactElement {
  const auth = useContext(AuthContext);
  const router = useRouter();

  const menuItems: Array<{ label: string, button?: boolean, href: string|{ pathname: string, query: any }, as?: string }> = [];

  if (!auth.signedIn) {
    menuItems.push(
      {
        label: 'Account',
        button: true,
        href: `${ router.route }?authModalStep=login`,
        as: '/login',
      }
    );
  } else {
    menuItems.push(
      {
        label: 'Logout',
        href: `${ router.route }?authModalStep=logout`,
        as: '/',
      }
    );
  }

  const renderMenuItems = () => {
    return menuItems.map((menuItem, menuItemIndex) => {
      let className = navigation.anchor;

      if (!menuItem.as && router.pathname === menuItem.href) {
        className = `${ className } ${ navigation.selected }`;
      }

      return (
        <li className={ st`item` } key={ menuItemIndex }>
          { menuItem.as
            ? <Link href={ menuItem.href } as={ menuItem.as } shallow>
              <a className={ className }>{ menuItem.label }</a>
            </Link>
            : <Link href={ menuItem.href }>
              <a className={ className }>{ menuItem.label }</a>
            </Link>
          }
        </li>
      );
    });
  };

  return (
    <nav className={ st`primary ${ props.visible && 'visible' }` }>
      <ul className={ st`list` }>
        { renderMenuItems() }
      </ul>
    </nav>
  );
}
