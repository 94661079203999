import { ReactElement } from 'react';
import Stl from '@root/lib/Stl';
import section from './Section.module.scss';
const st = (new Stl()).bind(section);

type Props = {
  children: ReactElement | ReactElement[];
  variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary';
  border?: boolean;
  narrow?: boolean;
};

export default function Section(props: Props): ReactElement {
  return (
    <section className={ st`default ${ props.variant ? props.variant : 'primary' } ${ props.border && 'border' } ${ props.narrow && 'narrow' }` }>
      {props.children}
    </section>
  );
}
