import { ReactElement } from 'react';
import Stl from '@root/lib/Stl';
import care from './Care.module.scss';
const st = (new Stl()).bind(care);

export default function Care(): ReactElement {
  return (
    <div className={ st`grid` }>
      <div className={ st`content` }>
        <h3 className={ st`heading` }>We take care of you</h3>
        <p className={ st`subtitle` }>We understand the importance of workplace safety. With YourDesq you are in charge when it comes to workplace conditions. Besides, hosts are able to approve or decline user requests.</p>
      </div>
      <ul className={ st`list` }>
        <li className={ st`item` }>Set your workplace rules</li>
        <li className={ st`item` }>You have the possibility to ask for a government issued ID when the user arrives</li>
        <li className={ st`item` }>The user indicates his/her professional background</li>
        <li className={ st`item` }>24/7 customer service</li>
      </ul>
    </div>
  );
}
