import React, { ReactElement, useContext } from 'react';
import Link from 'next/link';
import { AppContext } from '@root/context/AppContext';
import { Swiper, SwiperSlide } from 'swiper/react';

import Stl from '@root/lib/Stl';
import searchbanner from './SearchBanner.module.scss';
import { Button } from '..';
const st = (new Stl()).bind(searchbanner);

type Props = {
  variant?: 'primary' | 'secondary' | 'tertiary';
  background?: string;
  backgroundUrl?: string;
  backgroundUrl2x?: string;
  backgroundUrlmobile?: string;
  backgroundUrlmobile2x?: string;
  heading: ReactElement;
  description?: ReactElement;
  media?: string;
  media2x?: string;
  button?: { href?: string, variant?: string, label?: string, onClick?: UnknownFunc };
};

export default function SearchBanner(props: Props): ReactElement {
  const appContext = useContext(AppContext);

  const startSearch = async () => {
    appContext.setStartSearch(true);
  };

  return (
    <>
      <div className={ st`${ props.variant ? props.variant : 'primary' }` }>
        <div className={ st`background` }>
          <div className={ st`desktop` }>
            { props.backgroundUrl &&
            <img
              src={ props.backgroundUrl }
              srcSet={ `${ props.backgroundUrl } 1x, ${ props.backgroundUrl2x } 2x` }
            />
            }
          </div>
        </div>

        <div className={ st`container` }>
          <div className="grid">
            <div className={ st`heading-container` }>
              <h1 className={ st`heading` }>{ props.heading }</h1>
              { props.variant == 'tertiary' && <p className={ st`description` }>{ props.description }</p> }
            </div>

            { props.media &&
              <div className={ st`media` }>
                <div className={ st`image` }>
                  <img src={ props.media } srcSet={ `${ props.media } 1x, ${ props.media2x } 2x` } width="672px" height="480px" />
                </div>
              </div>
            }
          </div>

          <div className="grid">
            { (props.description || props.button) &&
              <>
                <div className={ st`description-container` }>
                  {props.description &&
                    <h4 className={ st`description` }>{ props.description }</h4>
                  }

                  {props.button && props.button.href &&
                    <Link href={ props.button.href }>
                      <a className={ st`button button--${ props.button.variant }` } onClick={ () => props.button.onClick() }>
                        {props.button.label}
                      </a>
                    </Link>
                  }
                  {props.button && !props.button.href &&
                    <a className={ st`button button--${ props.button.variant }` } onClick={ () => props.button.onClick() }>
                      {props.button.label}
                    </a>
                  }
                </div>
                <div className={ st`description-container description-container--large` }>
                  <Button variant='primary' onClick={ () => startSearch() }>
                    Start your search
                  </Button>
                  <Link href={ '/createaccount' }>
                    <Button variant='secondary'>
                    Sign up
                    </Button>
                  </Link>
                </div>
              </>
            }
          </div>
        </div>
        <div className={ st`mobileBackground` }>
          { props.backgroundUrlmobile &&
            <img
              src={ props.backgroundUrlmobile }
              srcSet={ `${ props.backgroundUrlmobile } 1x, ${ props.backgroundUrlmobile2x } 2x` }
            />
          }
        </div>
        <div className={ st`logos` }>
          <div className="grid">
            <div className={ st`logosList` }>
              <div className={ st`mobile` }>
                <Swiper
                  loop={ true }
                  slidesPerView={ 2 }
                  centeredSlides={ true }
                >
                  <SwiperSlide>
                    <img src="/images/logo-creative-valley.png" width="160px" height="80px" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/images/logo-tribes.png" width="160px" height="80px" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/images/logo-stardock.png" width="160px" height="80px" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/images/logo-adam.png" width="160px" height="80px" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/images/logo-collab.png" width="160px" height="80px" />
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className={ st`desktop` }>
                <img src="/images/logo-creative-valley.png" width="160px" height="80px" />
                <img src="/images/logo-tribes.png" width="160px" height="80px" />
                <img src="/images/logo-stardock.png" width="160px" height="80px" />
                <img src="/images/logo-adam.png" width="160px" height="80px" />
                <img src="/images/logo-collab.png" width="160px" height="80px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
