import { ReactElement } from 'react';

import Stl from '@root/lib/Stl';
import accountsignup from './AccountSignup.module.scss';
const st = (new Stl()).bind(accountsignup);

type Props = {
  setCurrentStep: UnknownFunc;
}

export default function AccountSignupFormVerification(props: Props): ReactElement {
  return (
    <div>
      <h4 className={ st`modalTitle` }>Your account has<br />been created</h4>
      <p className={ st`modalTextCentered` }>
        We’ve sent you a verification link to your email address. Please check your inbox and verify your email address.<br /> <br />
        If you didn&apos;t receive a verification email, please reach out to <a href="#">support@yourdesq.com</a>.
      </p>
      <button
        className={ st`button button--primary buttonWide` }
        onClick={ () => props.setCurrentStep('login') }
      >
        <span>Go to login</span>
      </button>
    </div>
  );
}
