import { gql } from '@apollo/client';

export const LISTING_BY_UUID = `
query ($uuid: uuid!) {
  listings(where: {uuid: {_eq: $uuid}}) {
    created_at
    id
    title
    updated_at
    user_id
    uuid
    description
    listings_rules {
      id
      status
      text
      category
    }
    listings_amenities {
      status
      amenity {
        id
        name
        order
        slug
      }
    }
    listings_locations {
      country
      city
      district
      created_at
      id
      lat
      listing_id
      type
      industry
      lng
      street_name
      building_name
      house_number
      postal_code
      updated_at
      surface_area
      weekdays
      check_in_start
      check_in_end
      check_out_end
    }
    listings_workspaces(where: {amount: {_gt: 0}}, order_by: {enum_workspaces_type: {comment: asc}}) {
      amount
      created_at
      id
      price
      status
      type
      updated_at
      uuid
      listings_workspaces_amenities(where: {status: {_eq: "active"}}, order_by: {workspace_amenity_id: asc}) {
        workspace_amenity {
          id
          name
          order
          slug
        }
      }
    }
    listings_media(where: {status: {_eq: "active"}}, order_by: {order: asc}) {
      id
      caption
      url
      order
      status
    }
  }
}
`;

export const OVERVIEW_LISTINGS = gql`
query ($minLat: numeric!, $maxLat: numeric!, $minLng: numeric!, $maxLng: numeric!, $amount: Int!) {
  listings(where: {status: {_eq: active}, user: {stripe_user_id: {_is_null: false}}, listings_locations: {lat: {_gt: $minLat, _lt: $maxLat}, lng: {_gt: $minLng, _lt: $maxLng}}, listings_workspaces: {amount: {_gte: $amount}, price: {_gt: 0}}, listings_media: {status: {_eq: "active"}}}) {
    created_at
    id
    title
    updated_at
    user_id
    uuid
    description
    listings_locations {
      country
      city
      district
      created_at
      id
      lat
      listing_id
      type
      industry
      lng
      street_name
      building_name
      house_number
      postal_code
      updated_at
      surface_area
      weekdays
      check_in_start
      check_in_end
      check_out_end
    }
    listings_workspaces(where: {amount: {_gt: 0}}, order_by: {enum_workspaces_type: {comment: asc}}) {
      amount
      created_at
      id
      price
      status
      type
      updated_at
      uuid
      listings_workspaces_amenities(order_by: {workspace_amenity_id: asc}) {
        workspace_amenity {
          id
          name
          order
          slug
        }
      }
    }
    listings_availabilities {
      dates
    }
    listings_media(where: {status: {_eq: "active"}}, order_by: {order: asc}) {
      id
      url
      order
      status
    }
    listings_rules {
      id
      status
      text
      category
    }
    listings_amenities(where: {status: {_eq: "active"}}) {
      status
      amenity {
        id
        name
        order
        slug
      }
    }
    bookings_aggregate(where: {
      status: {_in: [approved, pending]},
      transaction: {status: {_eq: "paid"}}
    }) {
      aggregate {
        sum {
          persons_amount
        }
      }
    }
  }
}
`;

export const OVERVIEW_LISTINGS_BY_DATE = gql`
query ($date: [String!]!, $minLat: numeric!, $maxLat: numeric!, $minLng: numeric!, $maxLng: numeric!, $amount: Int!) {
  listings(where: {status: {_eq: active}, user: {stripe_user_id: {_is_null: false}}, listings_availabilities: {dates: {_has_keys_any: $date}}, listings_locations: {lat: {_gt: $minLat, _lt: $maxLat}, lng: {_gt: $minLng, _lt: $maxLng}}, listings_workspaces: {amount: {_gte: $amount}, price: {_gt: 0}}, listings_media: {status: {_eq: "active"}}}) {
    created_at
    id
    title
    updated_at
    user_id
    uuid
    description
    listings_locations {
      country
      city
      district
      created_at
      id
      lat
      listing_id
      type
      industry
      lng
      street_name
      building_name
      house_number
      postal_code
      updated_at
      surface_area
      weekdays
      check_in_start
      check_in_end
      check_out_end
    }
    listings_workspaces(where: {amount: {_gt: 0}}, order_by: {enum_workspaces_type: {comment: asc}}) {
      amount
      created_at
      id
      price
      status
      type
      updated_at
      uuid
      listings_workspaces_amenities(order_by: {workspace_amenity_id: asc}) {
        workspace_amenity {
          id
          name
          order
          slug
        }
      }
    }
    listings_availabilities {
      dates
    }
    listings_media(where: {status: {_eq: "active"}}, order_by: {order: asc}) {
      id
      url
      order
      status
    }
    listings_rules {
      id
      status
      text
      category
    }
    listings_amenities(where: {status: {_eq: "active"}}) {
      status
      amenity {
        id
        name
        order
        slug
      }
    }
    bookings_aggregate(where: {
      date: {_in: $date},
      status: {_in: [approved, pending]},
      transaction: {status: {_eq: "paid"}}
    }) {
      aggregate {
        sum {
          persons_amount
        }
      }
    }
  }
}
`;

export const LISTINGS_BY_USER = `
query ($user_id: Int!) {
  listings(
    where: {
      status: {_neq: deleted},
      _or: [
        {
          user_id: {_eq: $user_id}
        },
        {
          listings_managers: {user_id: {_eq: $user_id}, status: {_eq: "active"}}
        }
      ]
    },
    order_by: {created_at: desc}
  ) {
    created_at
    id
    title
    updated_at
    user_id
    uuid
    description
    status
    listings_rules {
      id
      status
      text
      category
    }
    listings_amenities {
      status
      amenity {
        id
        name
        order
        slug
      }
    }
    listings_locations {
      country
      city
      district
      created_at
      id
      lat
      listing_id
      type
      industry
      lng
      street_name
      building_name
      house_number
      postal_code
      updated_at
      surface_area
      weekdays
      check_in_start
      check_in_end
      check_out_end
    }
    listings_workspaces(where: {amount: {_gt: 0}}, order_by: {enum_workspaces_type: {comment: asc}}) {
      amount
      created_at
      id
      price
      status
      type
      updated_at
      uuid
      listings_workspaces_amenities(order_by: {workspace_amenity_id: asc}) {
        workspace_amenity {
          id
          name
          order
          slug
        }
      }
    }
    listings_media(where: {status: {_eq: "active"}}, order_by: {order: asc}) {
      id
      caption
      url
      order
      status
    }
    is_manager: listings_managers(where: {user_id: {_eq: $user_id}}) {
      id
    }
  }
}
`;

export const LISTING_BY_UUID_BY_USER = `
query ($user_id: Int!, $uuid: uuid!) {
  listings(
    where: {
      status: {_neq: deleted},
      uuid: {_eq: $uuid},
      _or: [
        {
          user_id: {_eq: $user_id}
        },
        {
          listings_managers: {user_id: {_eq: $user_id}, status: {_eq: "active"}}
        }
      ]
    }
  ) {
    created_at
    id
    title
    updated_at
    user_id
    uuid
    description
    status
    margin_percentage
    receive_updates
    vat_number
    chamber_of_commerce_number
    company_name
    listings_rules {
      id
      status
      text
      category
    }
    listings_availabilities {
      id
      dates
    }
    listings_amenities {
      status
      amenity {
        id
        name
        order
        slug
      }
    }
    listings_locations {
      country
      city
      district
      created_at
      id
      lat
      listing_id
      type
      industry
      lng
      street_name
      building_name
      house_number
      postal_code
      updated_at
      surface_area
      weekdays
      check_in_start
      check_in_end
      check_out_end
    }
    listings_workspaces(order_by: {enum_workspaces_type: {comment: asc}}) {
      amount
      created_at
      id
      price
      status
      type
      updated_at
      uuid
      enum_workspaces_type {
        workspaces_vat {
          vat_percentage
        }
      }
      listings_workspaces_amenities(order_by: {workspace_amenity_id: asc}) {
        status
        workspace_amenity {
          id
          name
          order
          slug
        }
      }
    }
    listings_media(where: {status: {_eq: "active"}}, order_by: {order: asc}) {
      id
      caption
      url
      order
      status
    }
    listings_blacklist {
      industry
    }
    listings_whitelist {
      industry
    }
    is_manager: listings_managers(where: {user_id: {_eq: $user_id}}) {
      id
    }
  }
  amenities(order_by: {order: asc}) {
    id
    name
    order
    slug
  }
  workspace_amenities(order_by: {order: asc}) {
    id
    name
    order
    slug
  }
}
`;

export const NEW_LISTING = `
mutation ($user_id: Int!) {
  Listings_Create(user_id: $user_id) {
    uuid
  }
}
`;

export const SAVE_LISTING = `
mutation ($data: String!) {
  Listings_Save(data: $data) {
    data
  }
}
`;

export const AVAILABILITY_BY_DATE_BY_LISTING_ID = `
query ($date: [String!]!, $listing_id: Int!) {
  listings_availability(where: {dates: {_has_keys_any: $date}, listing_id: {_eq: $listing_id}}) {
    listing_id
    listing {
      bookings(where: {date: {_in: $date}, transaction: {status: {_eq: "paid"}}}) {
        workspace_type
        persons_amount
      }
    }
  }
}
`;

export const UPDATE_LISTING_STATUS = `
mutation ($listing_id: Int!, $status: enum_generic_status_enum!) {
  update_listings_by_pk(pk_columns: {id: $listing_id}, _set: {status: $status}) {
    id
  }
}
`;

export const SHOW_RECOMMENDED_LISTINGS = `
query {
  listings(where: {
    status: {_eq: active},
    listings_workspaces: {amount: {_gt: 0}, price: {_gt: 0}},
    listings_media: {url: {_is_null: false}, status: {_eq: "active"}}
  }) {
    id
    title
    description
    uuid
    listings_locations {
      city
      district
      surface_area
    }
    listings_workspaces(where: {amount: {_gt: 0}}, order_by: {price: asc}, limit: 1) {
      id
      price
    }
    listings_media(where: {status: {_eq: "active"}}, order_by: {order: asc}) {
      url
      order
      status
    }
  }
}
`;

export const LISTINGS_MANAGERS_INVITE = `
mutation ($invite_email: String!, $listing_id: Int!, $location: String!) {
  Listings_Managers_Invite(invite_email: $invite_email, listing_id: $listing_id, location: $location) {
    id
  }
}
`;

export const LISTINGS_MANAGERS_ACCEPT = `
mutation ($uuid: uuid!, $user_id: Int!, $current_date: date!) {
  update_listings_managers(
    where: {
      uuid: {_eq: $uuid},
      user_id: {_is_null: true},
      expired_at: {_gte: $current_date}
    },
    _set: {user_id: $user_id, status: "active"}
  ) {
    returning {
      listing {
        title
      }
    }
  }
}
`;

export const LISTINGS_MANAGERS_BY_LISTING_PK = `
query ($listing_id: Int!) {
  listings_by_pk(id: $listing_id) {
    listings_managers(where: {status: {_neq: "deleted"}}) {
      id
      invite_email
      expired_at
      status
      user {
        first_name
        last_name
        image
      }
    }
  }
}
`;

export const LISTINGS_MANAGERS_DELETE_BY_PK = `
mutation ($manager_id: Int!) {
  update_listings_managers_by_pk(pk_columns: {id: $manager_id}, _set: {status: "deleted"}) {
    id
  }
}
`;
