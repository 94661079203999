import { createContext, ReactElement, ReactChild, useEffect, useState, useContext } from 'react';
import { useRouter } from 'next/router';
import { useLazyQuery, useMutation } from '@apollo/client';
import { fetchAPI } from '@root/lib/fetchAPI';
import {
  AUTH_SIGN_IN,
  AUTH_SIGN_UP,
  AUTH_VERIFY,
  AUTH_REFRESH,
  AUTH_RESTORE_PASSWORD,
  AUTH_CHANGE_PASSWORD,
  AUTH_IS_LISTING_MANAGER,
} from '@queries/auth';
import { useCookies } from 'react-cookie';
import * as gtag from '../utils/gtag';
import { AnalyticsContext } from '@root/context/AnalyticsContext';

interface Props {
  children: ReactChild[]|ReactChild
}

export const AuthContext = createContext<any>(null);

export default function AuthProvider({ children }: Props): ReactElement {
  const [ cookies, setCookie ] = useCookies();
  const analytics = useContext(AnalyticsContext);

  const router = useRouter();

  const [ signInRequest, { loading: signInLoading, data: signInData, error: signInError } ] = useLazyQuery(AUTH_SIGN_IN);
  const [ signInErrorMessage, setSignInErrorMessage ] = useState<null|string>(null);
  const [ signInStatus, setSignInStatus ] = useState<number>(0);
  const [ signInAttempt, setSignInAttempt ] = useState<boolean>(false);
  const [ signedIn, setSignedIn ] = useState<boolean>(!!getAuthHeader());

  const [ signUpRequest, { loading: signUpLoading, data: signUpData, error: signUpError } ] = useMutation(AUTH_SIGN_UP);
  const [ signUpErrorMessage, setSignUpErrorMessage ] = useState<null|string>(null);
  const [ signUpStatus, setSignUpStatus ] = useState<number>(0);
  const [ signUpAttempt, setSignUpAttempt ] = useState<boolean>(false);

  const [ verifyRequest, { loading: verifyLoading, data: verifyData, error: verifyError } ] = useMutation(AUTH_VERIFY);
  const [ restorePasswordRequest ] = useMutation(AUTH_RESTORE_PASSWORD);
  const [ updatePassword ] = useMutation(AUTH_CHANGE_PASSWORD);


  const [ account, setAccount ] = useState(process.browser && JSON.parse(localStorage.getItem('user')) || null);
  const [ accountCookie, setAccountCookie ] = useState(cookies.user || null);

  // Log in logic
  const signIn = (data) => {
    // Reset all sign in states
    setSignInErrorMessage(null);
    setSignedIn(false);
    setSignInStatus(0);
    setSignInAttempt(false);

    // Check client-side if the e-mail and password are provided
    const emailPattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!emailPattern.test(data.email) || data.password.length === 0) {
      setSignInStatus(-1);
      // Show error and cancel request
      setSignInErrorMessage('Please fill in a valid email and password');

      return;
    }

    // Continue with the request
    signInRequest({
      variables: {
        email: data.email,
        password: data.password,
      },
    });
  };

  useEffect(() => {
    handleSignIn();
  }, [ signInLoading, signInData, signInError ]);

  const handleSignIn = async () => {
    if (signInLoading) {
      setSignInAttempt(true);
      return;
    }

    if (signInData) {
      if (signInData.Users_Signin) {
        // Signed in successfully
        storeAuthHeader(signInData.Users_Signin.jwt);
        setSignedIn(true);

        const accountData = Object.assign({}, signInData.Users_Signin);
        delete accountData.jwt;
        delete accountData.__typename;

        if (typeof accountData.is_admin !== 'undefined' && !accountData.is_admin) {
          delete accountData.is_admin;
        }

        accountData.isHost = accountData.stripe_user_id ? !!accountData.stripe_user_id.length : false;

        if (!accountData.isHost) {
          // Check if user is a listing manager. If so, let user have host features.
          const queryData = await fetchAPI(AUTH_IS_LISTING_MANAGER, {
            variables: {
              user_id: accountData.id,
            },
          });

          accountData.isHost = (queryData.data.listings_managers.length > 0);
        }

        setAccount(accountData);
        const userStringified = JSON.stringify(accountData);
        localStorage.setItem('user', userStringified);

        setCookie('user', userStringified, {
          maxAge: 31536000,
          path: '/',
        });
      }
      setSignInStatus(signInData.status);
    } else {
      setSignInStatus(-1);

      if (signInError && signInError.message) {
        if (signInError.message === 'Zero results') {
          setSignInErrorMessage('Incorrect email or password');
        }

        setSignInErrorMessage(signInError.message);
      } else if (signInAttempt) {
        console.log(signInError);
        setSignInErrorMessage('Error signing in. Please try again');
      }
    }

    setSignInAttempt(false);
  };

  const refresh = async () => {
    if (!account || !account.email) {
      return;
    }

    // Not using useLazyQuery of Apollo because the function would sometimes become undefined
    const refreshData = await fetchAPI(AUTH_REFRESH, {
      variables: {
        email: account.email,
      },
    });

    const userData = refreshData.data?.users[0];

    if (!userData) {
      setAccount(null);
      return;
    }

    userData.isHost = userData.stripe_user_id ? !!userData.stripe_user_id.length : false;

    if (!userData.isHost) {
      // Check if user is a listing manager. If so, let user have host features.
      const queryData = await fetchAPI(AUTH_IS_LISTING_MANAGER, {
        variables: {
          user_id: userData.id,
        },
      });

      userData.isHost = (queryData.data.listings_managers.length > 0);
    }

    setAccount(userData);

    const userStringified = JSON.stringify(userData);

    localStorage.setItem('user', userStringified);

    setCookie('user', userStringified, {
      maxAge: 31536000,
      path: '/',
    });
  };

  // Sign up logic
  const signUp = (data) => {
    // Reset all sign in states
    setSignUpErrorMessage(null);
    setSignUpStatus(0);
    setSignUpAttempt(false);

    // Continue with the request
    signUpRequest({
      variables: {
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        password: data.password,
      },
    })
      .then(() => {
        // Perform Gtag event for lead
        gtag.event({
          action: 'lead',
          category: 'engagement',
          label: 'User registered an account',
          value: 1,
        });

        // Sign up user to "Active users" audience on Mailchimp
        const jsonpScript = document.createElement('script');
        jsonpScript.src = `https://yourdesq.us7.list-manage.com/subscribe/post-json?u=03bea35e4d00a8c48d5a0569f&id=579d78397d&EMAIL=${ encodeURIComponent(data.email) }&FNAME=${ encodeURIComponent(data.first_name) }&LNAME=${ encodeURIComponent(data.last_name) }`;
        document.head.appendChild(jsonpScript);
        document.head.removeChild(jsonpScript);

        // Perform FB pixel event
        analytics.pixel?.track('Lead');
      })
      .catch(error => {});
  };

  useEffect(() => {
    if (signUpLoading) {
      setSignUpAttempt(true);
      return;
    }

    if (signUpData) {
      if (signUpData.Users_Register) {
        // Signed up successfully
        console.log('>>> Signed up');
      }
      setSignUpStatus(signUpData.status);
    } else {
      setSignUpStatus(-1);
      if (signUpError && signUpError.message.includes('Uniqueness')) {
        setSignUpErrorMessage('This email address has already been used');
      } else if (signUpError && signUpError.message === 'Please enter a valid email address') {
        setSignUpErrorMessage(signUpError.message);
      } else if (signUpAttempt) {
        console.dir(signUpError);
        setSignUpErrorMessage('Error signing up. Please try again');
      }
    }

    setSignUpAttempt(false);
  }, [ signUpLoading, signUpData, signUpError ]);

  // Sign out logic
  useEffect(() => {
    if (router.query.authModalStep === 'logout') {
      console.log('>>> Signed out!');
      removeAuthHeader();
      localStorage.removeItem('user');
      setCookie('user', null, {
        maxAge: 0,
        path: '/',
      });
      setSignedIn(false);
    }
  }, [ router.query.authModalStep ]);

  const contextValue = {
    account,
    accountCookie,

    signIn,
    signInData,
    signInLoading,
    signInErrorMessage,
    signInStatus,
    signedIn,

    signUp,
    signUpData,
    signUpLoading,
    signUpErrorMessage,
    signUpStatus,

    verifyRequest,
    verifyData,
    verifyLoading,
    verifyError,

    restorePasswordRequest,
    updatePassword,

    refresh,
  };

  // Run once
  useEffect(() => {
    if (signedIn) {
      refresh();
    }
  }, []);

  return (
    <AuthContext.Provider value={ contextValue }>
      { children }
    </AuthContext.Provider>
  );
}

export const storeAuthHeader = (auth: string): void => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem('Authorization', `${ auth }`);
  }
};

export const getAuthHeader = (): string | null => {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem('Authorization') || null;
  }

  return null;
};

export const removeAuthHeader = (): void => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem('Authorization');
  }
};
