import React, { ReactElement, useState } from 'react';
import Stl from '@root/lib/Stl';
import style from './AccountMenu.module.scss';
import Link from 'next/link';

const st = (new Stl()).bind(style);

export default function HostAccountSubmenu(): ReactElement {
  const [ accountListVisible, setAccountListVisible ] = useState(true);

  return (
    <div className={ st`hostSettingsSubmenu ${ accountListVisible && 'hostSettingsVisible' }` }>
      <div className={ st`accountListDivider` }></div>
      <button
        className={ st`accountButton` }
        onClick={ (event) => { event.stopPropagation(); setAccountListVisible(!accountListVisible); } }
      >
        <span>HOSTING</span>
        <img className={ st`accountButtonIcon` } src="/images/icons/chevron-up.svg" />
      </button>
      <div>
        <div className={ st`wrapper` }>
          <Link href="/dashboard/listings">
            <button className={ st`accountListButton` }><span>Listings</span></button>
          </Link>
          <Link href="/dashboard/booking-management">
            <button className={ st`accountListButton` }><span>Booking management</span></button>
          </Link>
          {/* <Link href="/dashboard/check-in">
            <button className={ st`accountListButton icon` }>
              Check-in user
              <img src="/images/icons/camera.svg" />
            </button>
          </Link> */}
        </div>
      </div>
    </div>
  );
}
