import { createContext, ReactChild, ReactElement, useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import ReactPixel from 'react-facebook-pixel';
import * as gtag from '@root/utils/gtag';

interface Props {
  children: ReactChild[]|ReactChild
}

import { CookiesContext } from '@context/CookiesContext';

export const AnalyticsContext = createContext<any>(null);

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

export default function AnalyticsProvider({ children }: Props): ReactElement {
  const router = useRouter();
  const cookies = useContext(CookiesContext);
  const [ pixel, setPixel ] = useState<typeof ReactPixel>(null);
  const [ route, setRoute ] = useState(router.asPath);

  const contextValue = {
    pixel,
  };

  useEffect(() => {
    if (!process.browser || !window || pixel) {
      return;
    }

    import('react-facebook-pixel')
      .then(x => x.default)
      .then(ReactPixel => {
        const options = {
          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          debug: true, // process.env.NEXT_PUBLIC_ENV === 'development', // enable logs
        };

        ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID, null, options);
        // TODO: Fix revoking and granting consent based on cookies.
        // Just doing reovokeConsent() and grantConsent() on the pixel object didn't seem to work
        // ReactPixel.revokeConsent();

        router.events.on('routeChangeComplete', (route) => {
          setRoute(route);
        });

        setPixel(ReactPixel);
      });
  }, [ pixel, cookies.cookiesAccepted ]);

  // Trigger every time the route updates
  useEffect(() => {
    if (pixel) {
      pixel.pageView();
      gtag.pageview(route);
    }
  }, [ pixel, route ]);

  // TODO: Fix revoking and granting consent based on cookies
  // useEffect(() => {
  //   if (pixel && cookies.cookiesAccepted) {
  //     pixel.grantConsent();
  //   }
  // }, [ pixel, cookies.cookiesAccepted ]);

  return (
    <AnalyticsContext.Provider value={ contextValue }>
      { children }
    </AnalyticsContext.Provider>
  );
}
