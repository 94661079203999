import { createContext, ReactChild, ReactElement, useState } from 'react';
import { CookiesProvider as ReactCookiesProvider } from 'react-cookie';

interface Props {
  children: ReactChild[]|ReactChild
}

export const CookiesContext = createContext<any>(null);

export default function CookiesProvider({ children }: Props): ReactElement {
  const [ cookiesAccepted, setCookiesAccepted ] = useState(false);

  const contextValue = {
    cookiesAccepted,
    setCookiesAccepted,
  };

  return (
    <CookiesContext.Provider value={ contextValue }>
      <ReactCookiesProvider>
        { children }
      </ReactCookiesProvider>
    </CookiesContext.Provider>
  );
}
