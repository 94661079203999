const API_URL = process.env.NEXT_PUBLIC_HOST_GRAPHQL;
const API_TOKEN = '';

export async function fetchAPI(query, { variables } = {}) {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ API_TOKEN }`,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  const data = await response.json();

  if (data.errors) {
    console.error(`⚠ Failed to fetch API: ${ data.errors[0].message }\n`, data.errors);
    console.error('➡ Query:', query);
    console.error('➡ Variables:\n', variables);
  }

  return data;
}
