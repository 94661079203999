import { ReactElement, useState, useContext } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import { Button } from '../..';

import { AuthContext } from '@context/AuthContext';

import Stl from '@root/lib/Stl';
import passwordreset from './PasswordReset.module.scss';
import { ModalsContext } from '@root/context/ModalsContext';
const st = (new Stl()).bind(passwordreset);

type Props = {
  status: string;
  onValidated: UnknownFunc;
  setCurrentStep: UnknownFunc;
  onClose: UnknownFunc;
}

export default function FormsPasswordReset(props: Props): ReactElement {
  const auth = useContext(AuthContext);
  const modal = useContext(ModalsContext);

  // Create reference variables for input fields
  const [ password, setPassword ] = useState('');
  const [ passwordConfirm, setPasswordConfirm ] = useState('');

  const [ errorMessage, setErrorMessage ] = useState(null);
  const [ successMessage, setSuccessMessage ] = useState(null);

  const url = window.location.search;
  const token = new URLSearchParams(url).get('token');

  if (props.status === 'error') {
    setErrorMessage('');
  }

  if (props.status === 'success') {
    setSuccessMessage('');
  }

  const submitForm = (event) => {
    event.preventDefault();

    if (!password) {
      return;
    }

    setErrorMessage(null);
    setSuccessMessage(null);

    if (!password) {
      setErrorMessage('Please fill in all fields.');
      return;
    }

    if (password !== passwordConfirm) {
      setErrorMessage('New password and confirmation must match.');
      return;
    }

    props.onValidated({
      PASSWORD: password,
      PASSWORDCONFIRM: passwordConfirm,
    });

    auth.updatePassword({
      variables: {
        password: password,
        token: token,
      },
    }).then((response) => {
      const message = response.data.Users_Restore_Password.status;
      modal.setSuccessNotification({
        modalVisible: true,
        content: message,
      });

      if (token) {
        props.onClose();
      }
      props.setCurrentStep('login');
    });
  };

  return (
    <form onSubmit={ submitForm }>
      <h4 className={ st`modalTitleSecondary` }>Reset password</h4>
      <p className={ st`modalText` }>Set your new password below.</p>

      { props.status !== 'success' &&
        <>
          <div className={ st`formRow` }>
            <label className={ st`formRowLabel` } htmlFor="userPassword">New password</label>
            <input
              className={ st`formInput` }
              type="password"
              placeholder="Your new password"
              name="PASSWORD"
              id="userPassword"
              value={ password }
              onChange={ (event: any) => setPassword(event.target.value) }
            />
          </div>

          <div className={ st`formRow` }>
            <label className={ st`formRowLabel` } htmlFor="userPasswordConfirm">Confirm new password</label>
            <input
              className={ st`formInput` }
              type="password"
              placeholder="Your new password"
              name="PASSWORDCONFIRM"
              id="userPasswordConfirm"
              value={ passwordConfirm }
              onChange={ (event: any) => setPasswordConfirm(event.target.value) }
            />
            <PasswordStrengthBar className={ st`strengthBar` } password={ passwordConfirm } />
          </div>

          <div className={ st`formRow formRowAction` }>
            <div>
              {errorMessage &&
                <span className={ st`errorMessage` } dangerouslySetInnerHTML={ { __html: errorMessage } } />
              }
              {successMessage &&
                <span className={ st`successMessage` } dangerouslySetInnerHTML={ { __html: successMessage } } />
              }
            </div>

            <button
              type="submit"
              className={ st`button button--primary buttonWide` }
              onClick={ submitForm }
            >
              Reset password
            </button>
          </div>
          {!token &&
            <div className={ st`formRow formFooter` }>
              <Button variant="text" onClick={ () => props.setCurrentStep('login') }>Back to login</Button>
            </div>}
        </>
      }

      { props.status === 'success' &&
        <div className={ st`formRow formRowLast` }>
          <div></div>
        </div>
      }
    </form>
  );
}
