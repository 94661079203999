import { ReactElement } from 'react';
import { useRouter } from 'next/router';
import Stl from '@root/lib/Stl';
import style from './StripeConnectSuccess.module.scss';
import Button from '../../Button/Button';
const st = (new Stl()).bind(style);

type Props = {
  setModalVisible: BoolVoidFunc;
};

export default function StripeConnectSuccess(props: Props): ReactElement {
  const router = useRouter();

  return (
    <div className={ st`container` }>
      <h4>Successfully connected</h4>
      <p>You&apos;ve setup your Stripe account and can now start creating your first listing!</p>
      <Button
        onClick={ () => {
          props.setModalVisible(false);
          router.push('/dashboard/listings');
        } }
      >
        Create your listing
      </Button>
    </div>
  );
}
