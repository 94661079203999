import { Dispatch, ReactElement, SetStateAction, useEffect } from 'react';
import Stl from '@root/lib/Stl';
import notification from './Notification.module.scss';
const st = (new Stl()).bind(notification);


type SuccessNotificationProps = {
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<{ modalVisible, content }>>;
  content: string;
}

export default function SuccessNotification(props: SuccessNotificationProps): ReactElement {

  useEffect(() => {
    if(props.modalVisible){
      const timer =setTimeout(() => {
        props.setModalVisible((prevState) => ({ modalVisible: false, content: prevState.content }));
      }, 4000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [ props.modalVisible ]);

  return (
    <div className={ st`modal ${ props.modalVisible ? 'modal--is-visible' : 'modal--is-hidden' }` }>
      <div className={ st`modal-content` }>
        <img src="/images/icons/checkmark-green.svg" />
        <p>{props.content || 'Account settings have been updated'}</p>
        <button onClick={ () => props.setModalVisible((prevState) => ({ modalVisible: false, content: prevState.content })) } >
          <svg 
            width="16" 
            height="16" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path 
              className={ st`close-icon` } 
              d="M3 3l10 10M13 3L3 13" 
              stroke="#111A49" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round" 
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
