import { ReactElement, useState } from 'react';
import FaqItem from './Item';
import Stl from '@root/lib/Stl';
import faq from './Faq.module.scss';
const st = (new Stl()).bind(faq);

type Item = {
  question: string;
  answer: string;
};

type Props = {
  items: Array<Item>;
};

export default function Faq(props: Props): ReactElement {
  const [ isExpanded, setIsExpanded ] = useState(false);
  const alwaysShowedItems = [];
  const expandedItems = [];

  props.items.forEach((item, index) => {
    if (index <= 5) {
      alwaysShowedItems.push(
        <FaqItem
          question={ item.question }
          answer={ item.answer }
          key={ item.question }
        />
      );
      return;
    }
    expandedItems.push(
      <FaqItem
        question={ item.question }
        answer={ item.answer }
        key={ item.question }
      />
    );
  });

  return (
    <div className={ st`primary` }>
      <div className={ st`container` }>
        <div className="grid">
          <ul className={ st`list` }>
            {alwaysShowedItems}
            <li className={ st` ${ isExpanded && 'list-expanded' }` }>
              <li className={ st`wrapper` }>
                {expandedItems}
              </li>
            </li>
          </ul>

          <div className={ st`expand-button-container` }>
            <button onClick={ () => setIsExpanded(!isExpanded) }>
              {isExpanded ? 'Show less' : 'Show more'}
            </button>
          </div>
          
        </div>
      </div>
    </div>
  );
}
