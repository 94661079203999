import { ReactElement, useContext } from 'react';
import { CookiesContext } from '@context/CookiesContext';
import * as gtag from '../../utils/gtag';

export default function BaseHead(): ReactElement {
  const cookies = useContext(CookiesContext);

  console.log(cookies?.cookiesAccepted);

  return (
    <>
      <script async src={ `https://www.googletagmanager.com/gtag/js?id=${ gtag.GA_TRACKING_ID }` } />
      <script
        dangerouslySetInnerHTML={ {
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments)}
            gtag('js', new Date());
            gtag('config', '${ gtag.GA_TRACKING_ID }');
            gtag('config', 'AW-440123107');
          `,
        } }
      />
      <script
        dangerouslySetInnerHTML={ {
          __html: `
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2433573,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'//static.hotjar.com/c/hotjar-','.js?sv=');
          `,
        } }
      />
      <meta name="description" content="YourDesq is the new booking platform for finding and offering on-demand, pay-per-use office space. Work close to home, travel less and improve productivity!" />
      <link rel="canonical" href="https://yourdesq.com" />
      <meta property="og:title" content="YourDesq | On Demand | Office | Desk | Nederland" />
      <meta property="og:description" content="YourDesq is the new booking platform for finding and offering on-demand, pay-per-use office space. Work close to home, travel less and improve productivity!" />
      <meta property="og:url" content="https://yourdesq.com" />
      <meta property="og:site_name" content="YourDesq" />
      <meta property="og:type" content="website" />
      <meta name="facebook-domain-verification" content="mpnnwvjq9pdkzlkkuj4sjx1nyfyh09" />
      <meta name="keywords" content="On-demand, booking, platform, hybrid working, remote working, daily, office" />
      <link rel="icon" href="/favicon.svg" />
    </>
  );
}
