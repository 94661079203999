import { ReactElement } from 'react';
import Stl from '@root/lib/Stl';
import intro from './Intro.module.scss';
const st = (new Stl()).bind(intro);

type Props = {
  title: string;
  subtitle?: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
  inverted?: boolean;
  columns?: any;
  small?: boolean;
  alignLeft?: boolean;
};

export default function SectionIntro(props: Props): ReactElement {
  return (
    <div className={ st`grid default ${ props.variant ? props.variant : 'primary' } ${ props.inverted && 'inverted' } ${ props.alignLeft && 'alignLeft' }` }>
      { props.small
        ? <h4 className={ st`title` }>{ props.title }</h4>
        : <h3 className={ st`title` }>{ props.title }</h3>
      }
      { props.subtitle && <div className={ st`subtitle` }>{ props.subtitle }</div> }
      <div className={ st`columns` }>
        { props.columns?.map((text: string, textIndex: number) =>
          <div className={ st`column` } key={ textIndex }>
            {text}
          </div>
        ) }
      </div>
    </div>
  );
}
