import { ReactElement, useState } from 'react';
import style from './Persons.module.scss';
import Stl from '@root/lib/Stl';
const st = (new Stl()).bind(style);

type Props = {
  className?: string;
  overlayBackground?: boolean;
  personsAmount?: number;
  setPersonsAmount?: UnknownFunc;
  variant?: 'primary' | 'secondary' | 'tertiary';
  onlyDropdownShow?: boolean;
};

export default function HeaderPersonsInput(props: Props): ReactElement {
  const [ dropdownVisible, setDropdownVisible ] = useState(props.onlyDropdownShow);

  const maxPersonsAmount = 10;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const personsArray = [ ...Array(maxPersonsAmount + 1).keys() ].slice(1);

  const [ selectedAmount, setSelectedAmount ] = props.setPersonsAmount
    ? [ props.personsAmount, props.setPersonsAmount ]
    : useState(0);

  const updateAmount = (amount) => {
    setSelectedAmount(amount);

    if (!props.onlyDropdownShow) {
      setDropdownVisible(false);
    }
  };

  return (
    <>
      <div className={ st`persons ${ props.className } ${ dropdownVisible && 'visible' } ${ props.variant } ${ props.onlyDropdownShow && 'onlyDropdown' }` }>
        {!props.onlyDropdownShow &&
        <button
          className={ st`button` }
          onClick={ () => setDropdownVisible(!dropdownVisible) }
          // onBlur={ () => setDropdownVisible(false) }
          type="button"
        >
          { selectedAmount
            ? <span>{selectedAmount}{selectedAmount === 1 ? ' person' : ' persons' }</span>
            : <span className={ st`unSelected` }>Select number of people</span> }
        </button>
        }
        <div className={ st`dropdown` }>
          <div className={ st`list` }>
            {
              personsArray.map((personCount, personIndex) => (
                <button
                  className={ st`label ${ selectedAmount === personCount && 'checked' }` }
                  key={ personIndex }
                  tabIndex={ 0 }
                  type="button"
                  onClick={ () => updateAmount(personCount) }
                >
                  <span>{ personCount }</span>
                </button>
              ))
            }
          </div>
        </div>
      </div>
      {!props.onlyDropdownShow &&
      <button
        className={ st`overlay ${ dropdownVisible && 'overlayVisible' } ${ props.overlayBackground && 'overlayBackground' }` }
        onClick={ () => setDropdownVisible(false) }
        tabIndex={ -1 }
        type="button"
      />
      }
    </>
  );
}
