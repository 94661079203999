import { ReactElement, useCallback, useEffect, useState } from 'react';
import style from './SelectInlineLabel.module.scss';
import Stl from '@root/lib/Stl';
const st = (new Stl()).bind(style);

type Props = {
  className?: string;
  label?: string;
  value?: string | number;
  optional?: boolean;
  prepend?: string;
  append?: string;
  required?: boolean;
  placeholder?: string;
  options?: Array<{ value: string, label: string }>;
  onChange?: UnknownFunc;
};

export default function FormSelect(props: Props): ReactElement {
  const [ value, setValue ] = useState(props.value || null);

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
    }
  }, [ props.value ]);

  useEffect(() => {
    props.onChange && props.onChange(value);
  }, [ value ]);

  const hasNoValue = useCallback(() => {
    return !value || value === 0 || value === '0' || value === '';
  }, [ value ]);

  return (
    <label className={ st`container` }>
      <div className={ st`wrapper` }>
        { props.prepend &&
          <span className={ st`label label--prepend` }>{ props.prepend }</span>
        }
        <select
          className={ st`select ${ hasNoValue() && 'select--no-value' }` }
          onChange={ (event) => setValue(event.target.value) }
          required={ props.required }
          value={ value || '' }
        >
          { props.placeholder &&
            <option
              className={ st`option` }
              value=''
              disabled
            >
              { props.placeholder }
            </option>
          }
          { props.options.map((option, optionIndex: number) =>
            <option
              key={ optionIndex }
              className={ st`option` }
              value={ option.value }
            >
              { option.label }
            </option>
          ) }
        </select>
        { props.append &&
          <span className={ st`label label--append` }>{ props.append }</span>
        }
      </div>
    </label>
  );
}
