import { ReactElement, useState, useEffect } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import Stl from '@root/lib/Stl';
import style from './Daypicker.module.scss';

const st = (new Stl()).bind(style);

type Props = {
  overlay?: string;
  label?: string;
  placeholder?: string;
  onDayClick?: any;
  selectedDays?: Date[];
  setSelectedDays?: UnknownFunc;
  overlayBackground?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'availability';
  onlyDropdownShow?: boolean;
};

export default function FormDaypicker(props: Props): ReactElement {
  const [ minDate, setMinDate ] = useState(null);

  useEffect(() => {
    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);

    if (minDate.getHours() > 11) {
      minDate.setDate(minDate.getDate() + 1);
    }

    setMinDate(minDate);
  }, []);

  return (
    <>
      {!props.onlyDropdownShow &&
      <>
        <label className={ st`label ${ props.overlayBackground && 'overlayBackground' } ${ props.variant }` }>
          { props.label && <span>{ props.label }</span> }
          <DayPickerInput
            classNames={ {
              container: st`container`,
              overlayWrapper: st`overlayWrapper`,
              overlay: st`overlay`,
            } }
            formatDate={ MomentLocaleUtils.formatDate }
            parseDate={ MomentLocaleUtils.parseDate }
            format="DD MMMM YYYY"
            placeholder={ props.placeholder || 'Date' }
            value={ (props.selectedDays && props.selectedDays[0]) || null }
            hideOnDayClick={ true }
            dayPickerProps={ {
              selectedDays: props.selectedDays,
              onDayClick: props.onDayClick,
              locale: 'en',
              localeUtils: MomentLocaleUtils,
              showOutsideDays: true,
              firstDayOfWeek: 1,
              disabledDays: {
                before: minDate,
              },
            } }
          />
          {props.selectedDays &&

          <div className={ st`clearDate` }>
            <button onClick={ () =>  props.setSelectedDays(null) }>
              <img src="/images/icons/close-alt.svg" />
            </button>
          </div>
          }
        </label>
      </>
      }
      {props.onlyDropdownShow &&
      <DayPicker
        showOutsideDays
        enableOutsideDaysClick
        selectedDays={ props.selectedDays && props.selectedDays[0] || null }
        onDayClick={ props.onDayClick }
        firstDayOfWeek={ 1 }
        disabledDays={ {
          before: minDate,
        } }
      />
      }
    </>
  );
}
