import { ReactElement, useEffect, useState, useContext } from 'react';
import Stl from '@root/lib/Stl';
import AnimatedNumber from 'react-animated-number';
import calculator from './RevenueCalculator.module.scss';
import SelectInlineLabel from '@app/Form/SelectInlineLabel';
import { useHookstate } from '@hookstate/core';
import { AuthContext } from '@context/AuthContext';
import { ModalsContext } from '@root/context/ModalsContext';
import { Button } from '@root/components/App';
import { useRouter } from 'next/router';
const st = (new Stl()).bind(calculator);

const allWorkspaceTypes = [
  {
    name: 'Workstations',
    slug: 'Workstation',
    description: 'Flex workstations within an open space',
    suggested_price: 17,
  },
  {
    name: 'Private offices',
    slug: 'PrivateOffice',
    description: 'Dedicated workstations within a shared office or open space',
    suggested_price: 25,
  },
  {
    name: 'Meeting rooms',
    slug: 'MeetingRoomMedium',
    description: 'A meeting room for 5 - 8 persons',
    suggested_price: 100,
  },
];

const allWorkspaceTypesMap = {};

allWorkspaceTypes.forEach(workspaceType => {
  allWorkspaceTypesMap[workspaceType.name] = workspaceType;
});

const maxAmount = 15;
const amountsList = [];

for (let amountIndex = 0; amountIndex <= maxAmount; amountIndex++) {
  amountsList.push({
    label: amountIndex,
    value: amountIndex,
  });
}

const maxDays = 31;
const daysList = [];

for (let amountIndex = 0; amountIndex <= maxDays; amountIndex++) {
  daysList.push({
    label: amountIndex,
    value: amountIndex,
  });
}

export default function RevenueCalculator(): ReactElement {
  const [ totalRevenue, setTotalRevenue ] = useState(0);
  const modalsContext = useContext(ModalsContext);
  const auth = useContext(AuthContext);
  const router = useRouter();

  const setSignupModalVisible = (visibility) => {
    if (auth.signedIn) {
      if (!auth.account.isHost) {
        modalsContext.setModalVisibleStripeConnect(visibility);
      } else {

      }
    } else {
      modalsContext.setModalVisibleHostSignup(visibility);
    }
  };

  const revenueState = useHookstate({
    'Workstations': {
      amount: Math.round(Math.random() * 10 + 5),
      availability: Math.round(Math.random() * 10 + 5),
    },
    'Private offices': {
      amount: 0,
      availability: 0,
    },
    'Meeting rooms': {
      amount: 0,
      availability: 0,
    },
  });

  useEffect(() => {
    let newTotalRevenue = 0;

    for (const workspaceType in revenueState) {
      const workspaceData = revenueState[workspaceType];
      
      newTotalRevenue += workspaceData.amount.value
        * workspaceData.availability.value
        * allWorkspaceTypesMap[workspaceType].suggested_price;
    }

    setTotalRevenue(newTotalRevenue);
  }, [ revenueState ]);

  const formatValue = value => Math.round(value).toLocaleString('nl-NL');

  return (
    <div className={ st`card` }>
      <h2 className={ st`card-title` }>Potential revenue</h2>
      <p className={ st`card-text` }>Specify the number of workplaces and their availability per month for a detailed revenue.</p>
      <div className={ st`table card-table` }>
        <div className="table-row">
          <div className={ st`table-cell table-cell--no-padding` }>Amount</div>
          <div className={ st`table-cell table-cell--no-padding row--padding-left` }>Monthly availability</div>
        </div>
        { Object.keys(revenueState).map((workspaceType, workspaceTypeIndex) => {
          return (
            <div
              className="table-row"
              key={ workspaceTypeIndex }
            >
              <div className={ st`table-cell table-cell--no-padding` }>
                <SelectInlineLabel
                  prepend={ workspaceType }
                  value={ revenueState[workspaceType].amount.value }
                  onChange={ value => revenueState[workspaceType].amount.set(value) }
                  options={ amountsList }
                />
              </div>
              <div className={ st`table-cell table-cell--no-padding row--padding-left` }>
                <SelectInlineLabel
                  append='Days'
                  value={ revenueState[workspaceType].availability.value }
                  onChange={ value => revenueState[workspaceType].availability.set(value) }
                  options={ daysList }
                />
              </div>
            </div>
          );
        }) }
      </div>

      <div className={ st`card-total` }>
        <div className={ st`card-total__left` }>
          <div className={ st`card-total__title` }>Average revenue</div>
          <div className={ st`card-total__subtitle` }>Per month</div>
        </div>
        <div className={ st`card-total__right` }>
          <div className={ st`card-total__value` }><span className={ st`currency` }>€</span>
            <AnimatedNumber
              value={ totalRevenue }
              formatValue={ formatValue }
              duration={ 1000 }
            />
          </div>
        </div>
        { (auth.signedIn && (
          auth.account?.isHost ? (
            <div className={ st`card-total__bottom` }>
              <Button onClick={ () => router.push('/dashboard/listings') }>Create your listing</Button>
            </div>
          ) : (
            <div className={ st`card-total__bottom` }>
              <Button onClick={ () => setSignupModalVisible(true) }>Start hosting</Button>
            </div>
          )
        )
        )}
      </div>
    </div>
  );
}
