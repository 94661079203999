import { ReactElement, useState, useEffect } from 'react';
import Stl from '@root/lib/Stl';
import style from './Share.module.scss';
import { shareOption } from './constants';
const st = (new Stl()).bind(style);

export default function Share(): ReactElement {
  const [ url, setUrl ] = useState('');
  
  useEffect(() => {    
    if (typeof window !== 'undefined') {
      setUrl(String(window.location.href));
    }
  }, []);
  
  const rendererLinks = shareOption.map((option, index) =>
    <a key={ index } href={ `${ option.urlBase }${ encodeURI(url) }` }>
      <img src={ option.imgSrc } />
      {option.title}
    </a>
  );
  
  //Needed for dynamic body insertion
  const sendMail = (event) => {
    event.preventDefault();
    const mailto ='mailto:?body='+url;
    window.location.href = mailto;
  };

  return (
    <div className={ st`container` }>
      <h3  className={ st`title` }>Share this office with friends and family</h3>
      <div className={ st`shareBox` }>
        {rendererLinks}
        <a onClick={ () => navigator.clipboard.writeText(url) }>
          <img src='/images/icons/link.svg' />
          Copy link
        </a>
        <a onClick={ (event)=> sendMail(event) }>
          <img src='/images/icons/email.svg' />
          Email
        </a>
      </div>
    </div>
  );
}
