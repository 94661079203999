import { ReactElement } from 'react';
import { Button } from '..';
import Stl from '@root/lib/Stl';
import cookienotification from './CookieNotification.module.scss';
import Link from 'next/link';
const st = (new Stl()).bind(cookienotification);

type Props = {
  modalVisible: boolean;
  setModalVisible: BoolVoidFunc;
  setCookiesAccepted: BoolVoidFunc;
  name: string;
}

export default function CookieNotification(props: Props): ReactElement {
  const acceptCookies = () => {
    window.localStorage.setItem(props.name, '1');
    props.setCookiesAccepted(true);
    props.setModalVisible(false);
  };

  return (
    <div className={ st`modal ${ props.modalVisible ? 'modal--is-visible' : 'modal--is-hidden' }` }>
      <p>We use cookies to analyze site performance and deliver personalized content. By clicking “Accept Cookies”, you consent to our&nbsp;
        <Link href="/terms-and-conditions">Terms and conditions</Link>.</p>
      <Button
        onClick={ acceptCookies }
      >
          Accept Cookies
      </Button>
    </div>
  );
}
