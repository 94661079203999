import { ReactElement, useState } from 'react';

import * as gtag from '../../../../utils/gtag';

import Stl from '@root/lib/Stl';
import form from '../Form.module.scss';
const st = (new Stl()).bind(form);

type Props = {
  status: string;
  message: string;
  onValidated: UnknownFunc;
  setModalVisible: BoolVoidFunc;
}

export default function NewsletterSignupForm(props: Props): ReactElement {
  // Create reference variables for input fields
  let firstName, lastName, email, newsletter, privacy;

  const [ modalTitle, setModalTitle ] = useState<string>(null);
  const [ modalText, setModalText ] = useState<string>(null);

  const [ errorMessage, setErrorMessage ] = useState(null);
  const [ successMessage, setSuccessMessage ] = useState(null);

  if (props.status === 'error' && props.message && props.message !== errorMessage) {
    setErrorMessage(props.message);
  }

  if (props.status === 'success' && props.message && props.message !== successMessage) {
    setModalTitle('Great, almost there!');
    setModalText('Don’t forget to complete your registration by following the steps in the email.');
    setSuccessMessage(props.message);

    // Perform Gtag event for sign_up
    gtag.event({
      action: 'host_signup_landingpage',
      category: 'engagement',
      label: 'Host signup using form',
      value: 1,
    });
  }

  const submitForm = (event) => {
    event.preventDefault();

    if (!firstName) {
      return;
    }

    setErrorMessage(null);
    setSuccessMessage(null);

    if (!firstName.value || !lastName.value || email.value.indexOf('@') === -1) {
      setErrorMessage('Please fill in all fields.');
      return;
    }

    if (!privacy.checked) {
      setErrorMessage('You need to agree with our privacy statement.');
      return;
    }

    if (newsletter.checked) {
      // Also subscribe to the newsletter with the following method:
      const jsonpScript = document.createElement('script');
      jsonpScript.src = `https://yourdesq.us7.list-manage.com/subscribe/post-json?u=03bea35e4d00a8c48d5a0569f&amp;id=e530e0082d&EMAIL=${ encodeURIComponent(email.value) }&c=__jp1`;
      document.head.appendChild(jsonpScript);
      document.head.removeChild(jsonpScript);
    }

    props.onValidated({
      EMAIL: email.value,
      FNAME: firstName.value,
      LNAME: lastName.value,
    });
  };

  return (
    <form onSubmit={ submitForm }>
      <h2 className={ st`modalTitle` }>{ modalTitle || 'Become a host' }</h2>
      <p className={ st`modalText` }>{ modalText || 'Pre-sign up as a host and create your ad once we go live. Registration is only complete after following the steps in the email.' }</p>
      { props.status !== 'success' &&
        <>
          <div className={ st`formRow` }>
            <label className={ st`formRowLabel` } htmlFor="hostFirstname">First name</label>
            <input
              className={ st`formInput }` }
              ref={ node => (firstName = node) }
              type="text"
              name="FNAME"
              id="hostFirstname"
            />
          </div>
          <div className={ st`formRow` }>
            <label className={ st`formRowLabel` } htmlFor="hostLastName">Last name</label>
            <input
              className={ st`formInput` }
              ref={ node => (lastName = node) }
              type="text"
              name="LNAME"
              id="hostLastName"
            />
          </div>
          <div className={ st`formRow` }>
            <label className={ st`formRowLabel` } htmlFor="hostEmail">E-mail</label>
            <input
              className={ st`formInput` }
              ref={ node => (email = node) }
              type="email"
              placeholder="Your e-mail address"
              name="EMAIL"
              id="hostEmail"
            />
          </div>

          <div className={ st`formRow formRowCheckbox` }>
            <label className={ st`formRowLabelCheckbox` } htmlFor="hostNewsletter">
              <input
                className={ st`formCheckbox` }
                ref={ node => (newsletter = node) }
                type="checkbox"
                id="hostNewsletter"
              />
              <span>I want to receive YourDesq product updates</span>
            </label>
          </div>
          <div className={ st`formRow formRowCheckbox` }>
            <label className={ st`formRowLabelCheckbox` } htmlFor="hostPrivacy">
              <input
                className={ st`formCheckbox` }
                ref={ node => (privacy = node) }
                type="checkbox"
                id="hostPrivacy"
              />
              <span>I agree with the <a href="https://www.websitepolicies.com/policies/view/uLCIDWI5" rel="noreferrer" target="_blank">GDPR & privacy statement</a></span>
            </label>
          </div>

          <div className={ st`formRow formRowLast` }>
            <div>
              { errorMessage &&
                <span className={ st`errorMessage` } dangerouslySetInnerHTML={ { __html: errorMessage } } />
              }
              { successMessage &&
                <span className={ st`successMessage` } dangerouslySetInnerHTML={ { __html: successMessage } } />
              }
            </div>

            <button
              type="submit"
              className={ st`button button--primary` }
              onClick={ submitForm }
            >
              Register
            </button>
          </div>
        </>
      }

      { props.status === 'success' &&
        <div className={ st`formRow formRowLast` }>
          <div></div>

          <button
            type="submit"
            className={ st`button button--primary` }
            onClick={ () => props.setModalVisible(false) }
          >
            OK
          </button>
        </div>
      }
    </form>
  );
}
