import { ReactElement } from 'react';
import Stl from '@root/lib/Stl';
import button from './Button.module.scss';
const st = (new Stl()).bind(button);

type Props = {
  variant?: 'primary' | 'secondary' | 'inverted' | 'text';
  children?: any;
  onClick?: UnknownFunc;
  spaced?: boolean;
}

export default function Button(props: Props): ReactElement {
  return (
    <button
      className={ st`default ${ props.variant ? props.variant : 'primary' } ${ props.spaced && 'spaced' }` }
      onClick={ (event) => { props.onClick ? props.onClick(event) : false; } }
    >
      { props.children }
    </button>
  );
}
