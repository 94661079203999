import { ReactElement } from 'react';
import Stl from '@root/lib/Stl';
import mediacontent from './MediaContent.module.scss';
const st = (new Stl()).bind(mediacontent);

type Props = {
  variant?: 'primary';
  heading: string;
  description: string;
  button?: {
    label: string,
    href: string
  };
  media: ReactElement;
  offset?: boolean;
};

export default function MediaContent(props: Props): ReactElement {
  return (
    <div className={ st`${ props.variant ? props.variant : 'primary' }` }>
      <div className="grid">
        <div className={ st`content` }>
          <h3 className={ st`heading` }>{ props.heading }</h3>
          <p className={ st`description` }>{ props.description }</p>
          <a className="button button--primary" href={ props.button.href }>{ props.button.label }</a>
        </div>
        <div className={ st`media ${ props.offset ? 'offset' : '' }` }>
          <div className={ st`image` }>
            { props.media }
          </div>
        </div>
      </div>
    </div>
  );
}
