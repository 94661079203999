import { ReactElement, useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { AppContext } from '@context/AppContext';
import Link from 'next/link';
import Stl from '@root/lib/Stl';
import recommendations from './Recommendations.module.scss';
const st = (new Stl()).bind(recommendations);

import { SHOW_RECOMMENDED_LISTINGS } from '@queries/listings';

import { fetchAPI } from '@root/lib/fetchAPI';

type Props = {
  variant?: 'primary';
  heading: string;
  description: string;
  button?: { href?: string, label?: string, onClick?: UnknownFunc };
};

type ItemType = {
  image: string,
  link: string,
  heading: string,
  location: string,
  price: string,
};

export default function Recommendations(props: Props): ReactElement {
  const appContext = useContext(AppContext);
  const [ recommendationItems, setRecommendationItems ] = useState([] as ItemType[]);

  useEffect(() => {
    getRecommendation();
  }, []);

  const getRecommendation = async () => {
    const items = [] as ItemType[];

    const { data: data } = await fetchAPI(SHOW_RECOMMENDED_LISTINGS);

    const workspaces = takeRandomWorkspaces(data.listings);

    workspaces.forEach(workspace => {
      const location = [
        workspace?.listings_locations[0].district,
        workspace?.listings_locations[0].city,
        workspace?.listings_locations[0].surface_area,
      ];

      const filteredLocation = location.filter(currentLocation => Boolean(currentLocation));

      items.push({
        image: workspace?.listings_media[0]?.url,
        link: `/listings/${ workspace?.uuid }`,
        heading: workspace?.title,
        location: filteredLocation?.join(', '),
        price: workspace?.listings_workspaces[0].price,
      });
    });
    setRecommendationItems(items);
  };

  const getRandomIndex = (arr) => {
    return Math.floor(Math.random() * arr.length);
  };

  const takeRandomWorkspaces = (data) => {
    const result = [];
    let copyData = data;

    for (let i = 0; i < 3; i += 1) {
      const index = getRandomIndex(copyData);

      result.push(copyData[index]);
      copyData = copyData.slice(0, index).concat(copyData.slice(index + 1));
    }
    return result;
  };


  return (
    <div className={ st`${ props.variant ? props.variant : 'primary' }` }>
      <div className="grid">
        <div className={ st`intro` }>
          <h3 className={ st`heading` }>{props.heading}</h3>
          <p className={ st`description` }>{props.description}</p>

          {(props.button && props.button.href) && (
            <Link href={ props.button.href }>
              { props.button.onClick ? (
                <a className="button button--primary" onClick={ () => props.button.onClick() }>{props.button.label}</a>
              ) : (
                <a className="button button--primary">{props.button.label}</a>
              )}
            </Link>
          )}

          {(props.button && !props.button.href) && (
            props.button.onClick ? (
              <a className="button button--primary" onClick={ () => props.button.onClick() }>{props.button.label}</a>
            ) : (
              <a className="button button--primary">{props.button.label}</a>
            )
          )}
        </div>
      </div>
      <div className="grid">
        <div className={ st`list` }>
          {recommendationItems.map((item: any, index) => (
            item.price &&
            <Link key={ index } href={ item.link }>
              <div className={ st`item` }>
                <div className={ st`image` }>
                  <img src={ item.image } />
                </div>
                <div className={ st`content` }>
                  <h5 className={ st`heading` }>{item.heading}</h5>
                  <div className={ st`location` }>{item.location}</div>
                  <div className={ st`pricing` }>
                    <span className={ st`pricingLabel` }>Per day from</span>
                    <span className={ st`price` }>{`€ ${ item.price }`}</span>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
