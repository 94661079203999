import { ReactElement } from 'react';
import Stl from '@root/lib/Stl';
import cta from './Cta.module.scss';
const st = (new Stl()).bind(cta);

type Props = {
  title: string;
  subtitle?: ReactElement;
  buttons: any;
}

export default function Cta(props: Props): ReactElement {
  return (
    <div className={ st`primary` }>
      <div className="grid">
        <h2 className={ st`heading` }>{props.title}</h2>
        <p className={ st`description` }>​{props.subtitle}</p>
        <div className={ st`buttons` }>
          {props.buttons?.map((button: any) => button)}
        </div>
      </div>
    </div>
  );
}
