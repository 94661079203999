import Link from 'next/link';
import { ReactElement } from 'react';
import Stl from '@root/lib/Stl';
import footer from './Footer.module.scss';
const st = (new Stl()).bind(footer);

export default function Footer(): ReactElement {
  return (
    <footer className={ `${ footer.primary } no-print` }>
      <div className={ st`prefooter` }>
        <div className={ st`group` }>
          <nav className={ st`navigationSecondary` }>
            <ul className={ st`list_items` }>
              <li className={ st`item` }>
                <Link href="/">
                  <a className={ st`anchor` }>Home</a>
                </Link>
              </li>
              <li className={ st`item` }>
                <Link href="/become-a-host">
                  <a className={ st`anchor` }>Become a host</a>
                </Link>
              </li>
              <li className={ st`item` }>
                <Link href="/about">
                  <a className={ st`anchor` }>About</a>
                </Link>
              </li>
              <li className={ st`item` }>
                <Link href="mailto:hello@yourdesq.com">
                  <a className={ st`anchor` }>Contact</a>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className={ st`prefooter-bottom` }>
        <div className={ st`group` }>
          <nav className={ st`navigationSecondary` }>
            <ul className={ st`prefooter-column` }>
              <li>KVK: 81021453</li>
              <li>BTW: NL861893013B01</li>
            </ul>
            <ul className={ st`prefooter-column` }>
              <li>Gooimeer 2-05</li>
              <li>1411 DC Naarden</li>
            </ul>
            <ul className={ st`prefooter-column` }>
              <li><a href="tel:+31623196453" className={ st`anchor` }>06 23196453</a></li>
              <li><a href="mailto:hello@yourdesq.com" className={ st`anchor` }>hello@yourdesq.com</a></li>
            </ul>
            <ul className={ st`prefooter-column` }>
              <li>
                <Link href="https://www.websitepolicies.com/policies/view/uLCIDWI5">
                  <a className={ st`anchor` } target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                </Link>
              </li>
              <li>
                <Link href="/terms-and-conditions">
                  <a className={ st`anchor` }>Terms and conditions</a>
                </Link>
              </li>
              {/* <li className={ st`item` }>
                <Link href="/">
                  <a className={ st`anchor` }>Cancellation policy</a>
                </Link>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
      <div className={ st`container` }>
        <div className={ st`group` }>
          <div className={ st`column` }>
            <span>©2021 by YourDesq.</span>
          </div>
          <div className={ st`column` }>
            <nav className={ st`social` }>
              <ul className={ st`list` }>
                <li className={ st`item` }>
                  <Link href="https://www.instagram.com/yourdesq/">
                    <a className={ st`anchor` } target="_blank" rel="noopener noreferrer">
                      <img src="/images/icons/social-instagram.svg" />
                    </a>
                  </Link>
                </li>
                <li className={ st`item` }>
                  <Link href="https://www.linkedin.com/company/yourdesq/">
                    <a className={ st`anchor` } target="_blank" rel="noopener noreferrer">
                      <img src="/images/icons/social-linkedin.svg" />
                    </a>
                  </Link>
                </li>
                <li className={ st`item` }>
                  <Link href="https://www.facebook.com/Desq-100617818568743">
                    <a className={ st`anchor` } target="_blank" rel="noopener noreferrer">
                      <img src="/images/icons/social-facebook.svg" />
                    </a>
                  </Link>
                </li>
                <li className={ st`item` }>
                  <Link href="https://twitter.com/YourDesq">
                    <a className={ st`anchor` } target="_blank" rel="noopener noreferrer">
                      <img src="/images/icons/social-twitter.svg" />
                    </a>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
}
