export const shareOption = [
  //   { title: 'Instagram', base: 'https://www.instagram.com/?url=' },
  {
    title: 'LinkedIn',
    urlBase: 'https://www.linkedin.com/sharing/share-offsite/?url=',
    imgSrc: '/images/icons/social-linkedin.svg',
  },
  {
    title: 'Facebook',
    urlBase: 'https://www.facebook.com/sharer/sharer.php?u=',
    imgSrc: '/images/icons/social-facebook.svg',
  },
];
