import { ReactElement, useState } from 'react';

import * as gtag from '../../../../utils/gtag';

import Stl from '@root/lib/Stl';
import form from '../Form.module.scss';
const st = (new Stl()).bind(form);

type Props = {
  status: string;
  message: string;
  onValidated: UnknownFunc;
}

export default function NewsletterSignupForm(props: Props): ReactElement {
  let email;

  const [ errorMessage, setErrorMessage ] = useState(null);
  const [ successMessage, setSuccessMessage ] = useState(null);

  if (props.status === 'error' && props.message && props.message !== errorMessage) {
    setErrorMessage(props.message);
  }

  if (props.status === 'success' && props.message && props.message !== successMessage) {
    setSuccessMessage(props.message);

    // Perform Gtag event for sign_up
    gtag.event({
      action: 'newsletter_signup_landingpage',
      category: 'engagement',
      label: 'Newsletter signup using form',
      value: 1,
    });
  }

  const submitForm = (event) => {
    event.preventDefault();

    setErrorMessage(null);
    setSuccessMessage(null);

    if (!email || email.value.indexOf('@') === -1) {
      setErrorMessage('Please enter a valid e-mail address.');
      return;
    }

    props.onValidated({
      EMAIL: email.value,
    });
  };

  return (
    <form onSubmit={ submitForm }>
      <h2 className={ st`modalTitle` }>Subscribe to our newsletter</h2>
      <p className={ st`modalText` }>Subscribe to our newsletter to receive the latest news and updates about YourDesq.</p>
      <div className={ st`formRowSingleLine` }>
        <input
          className={ st`formInput formInputSingleLine` }
          ref={ node => (email = node) }
          type="email"
          placeholder="Enter email address"
        />

        <button
          type="submit"
          className={ st`button button--primary formButtonSingleLine` }
          onClick={ submitForm }
        >
          Sign up for updates
        </button>
      </div>
      { errorMessage &&
        <span className={ st`errorMessage` } dangerouslySetInnerHTML={ { __html: errorMessage } } />
      }
      { successMessage &&
        <span className={ st`successMessage` } dangerouslySetInnerHTML={ { __html: successMessage } } />
      }
    </form>
  );
}
