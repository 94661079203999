import { ReactElement, useContext, useEffect, useState } from 'react';
import { AppContext } from '@context/AppContext';
import { AuthContext } from '@context/AuthContext';
import { useRouter } from 'next/router';
import { ModalsContext } from '@root/context/ModalsContext';
import Link from 'next/link';
import Stl from '@root/lib/Stl';
// import { DateUtils } from 'react-day-picker';
import { Button } from '..';

import header from './Header.module.scss';
import { LocationSearch } from '@root/components/Website';
import HeaderAccountMenu from './AccountMenu';
const st = (new Stl()).bind(header);

type Props = {
  navigationVisible: boolean;
  toggleDropDown: UnknownFunc;
  // locationSearchHidden: boolean;
}

export default function MainHeader(props: Props): ReactElement {
  const router = useRouter();
  const auth = useContext(AuthContext);
  const appContext = useContext(AppContext);
  const modalsContext = useContext(ModalsContext);

  //fix incorrect render
  const [ signedIn, setSignedIn ] = useState(false);

  useEffect(() => {
    setSignedIn(!!auth.signedIn);

  }, [ auth.signedIn ]);

  return (
    <>
      <div className={ st`left` }>
        <Link href='/'>
          <a className={ st`logo` }>
            <img src='/images/logo.svg' />
          </a>
        </Link>
      </div>
      {appContext.headerVariant !== 'clear' && appContext.headerVariant !== 'simple' &&
          <div className={ st`center no-print` }>
            <div className={ st`container` }>
              <LocationSearch />
            </div>
          </div>
      }
      {appContext.headerVariant !== 'clear' && appContext.headerVariant !== 'search' &&
          <div className={ st`right no-print` }>
            {!signedIn ? (
              <>
                <div className={ st`desktop-anchors` }>
                  <Link href="/about">
                    <a className={ st`text-anchor` }>About</a>
                  </Link>

                  <Link href="/become-a-host">
                    <a className={ st`text-anchor` }>Become a host</a>
                  </Link>

                  <Button
                    variant='inverted'
                    onClick={ () => {
                      modalsContext.setInternalAuthModalStep('login');
                      modalsContext.setModalVisibleSignin(true);
                    } }
                  >
                    Log in
                  </Button>

                  <Button
                    variant='primary'
                    onClick={ () => {
                      modalsContext.setInternalAuthModalStep('signup');
                      modalsContext.setModalVisibleSignin(true);
                    } }
                  >
                    Sign up
                  </Button>
                </div>

                <div className="mobileNavigation">
                  <button
                    className={ st`toggle ${ props.navigationVisible && 'toggleActive' }` }
                    onClick={ props.toggleDropDown }>
                    <div className={ st`toggleIcon` }></div>
                  </button>
                  <div className={ st`mobileDropdown ${ props.navigationVisible && 'visible' }` }>
                    <button
                      className={ st`mobileDropdownButton` }
                      onClick={ () => {
                        modalsContext.setInternalAuthModalStep('signup');
                        modalsContext.setModalVisibleSignin(true);
                      } }
                    >
                      Sign up
                    </button>
                    <button
                      className={ st`mobileDropdownButton` }
                      onClick={ () => {
                        modalsContext.setInternalAuthModalStep('login');
                        modalsContext.setModalVisibleSignin(true);
                      } }
                    >
                      Log in
                    </button>
                    <div className={ st`mobileDropdownDivider` }></div>
                    <Link href="/about">
                      <button className={ st`mobileDropdownButton` }>About</button>
                    </Link>
                    <Link href="/become-a-host">
                      <button className={ st`mobileDropdownButton` }>Become a host</button>
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={ st`desktop-anchors` }>
                  {auth.account?.isHost ? (
                    router.pathname !== '/dashboard/listings' &&
                    router.pathname !== '/dashboard/listings/[listing_uuid]' && (
                      <Link href="/dashboard/listings">
                        <a className={ st`text-anchor secondary` }>Create your listing</a>
                      </Link>
                    )
                  ) : (
                    <Link href="/become-a-host">
                      <a className={ st`text-anchor secondary` }>Start hosting</a>
                    </Link>
                  )}
                </div>
              </>
            )}
          </div>
      }
      {auth.signedIn && appContext.headerVariant !== 'search' &&
          router.pathname !== '/dashboard/listings/[listing_uuid]' && <HeaderAccountMenu />
      }
    </>
  );
}
