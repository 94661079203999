import { ReactElement, useContext, useState } from 'react';
import { AppContext } from '@context/AppContext';
import Button from '@app/Button/Button';
import AutoComplete from '@app/Header/AutoComplete';
import Persons from '@app/Form/Persons';
import FormDaypicker from '@app/Form/Daypicker';

import Stl from '@root/lib/Stl';
import locationsearch from './LocationSearch.module.scss';
import LocationSearchMobile from './LocationSearchMobile';
import autoComplete from '@app/Header/AutoComplete.module.scss';

const st = (new Stl()).bind(locationsearch);

type Props = {
  wrappable?: boolean;
  detailed?: boolean;
}

export default function LocationSearch(props: Props): ReactElement {
  const appContext = useContext(AppContext);
  const [ expanded, setExpanded ] = useState(props.detailed || false);

  const [ selectedDays, setSelectedDays ] = useState(null);

  const [ personsAmount, setPersonsAmount ] = useState(null);

  const handleDayClick = (day) => {
    // Select only one day for now
    setSelectedDays([ day ]);
  };

  const startSearch = (event) => {
    event.preventDefault();
    setExpanded(false);

    appContext.setHeaderVariant('default');

    if (!selectedDays) {
      appContext.setStartSearch(true);
      return;
    }

    appContext.setSelectedDays(selectedDays);
    appContext.setPersonsAmount(personsAmount);
    appContext.setStartSearch(true);
  };

  const expandSearchBar = () => {
    document.body.addEventListener('click', closeSearchBar);
    setExpanded(true);
    appContext.setHeaderVariant('search');
  };

  const closeSearchBar = (event?) => {
    // fix autocomplete disable location button click
    if (event?.target?.parentNode?.className.includes(autoComplete['disable-user-location'])) {
      setExpanded(true);
      return;
    }

    if (
      !event.target?.closest(`.${ st`search` }`)
      && !document.activeElement.className.includes('AutoComplete_input')
    ){
      setExpanded(false);
      document.body.removeEventListener('click', closeSearchBar);
      appContext.setHeaderVariant('default');
    }
  };

  const toggleExpand = (event) => {
    expanded ? closeSearchBar(event) : expandSearchBar();
  };

  return (
    <>
      <form
        className={ st`search ${ props.wrappable ? 'secondary' : 'primary' } ${ props.detailed && 'detailed' }` }
        onSubmit={ (event) => startSearch(event) }
      >
        <div className={ st`desktop ${ expanded && 'details-visible' }` }>
          <div className={ st`inputs-container` }>
            <AutoComplete
              placeholder={ appContext.isSearching? 'Select location' : 'Where do you want to work?' }
              searchIcon={ (appContext.isSearching || !appContext.selectedLocation) && !appContext.userLocation }
              variant={ props.wrappable ? 'secondary' : 'tertiary' }
              onFocus={ (event) => { if(!props.detailed) { toggleExpand(event); } } }
            />
            <Persons
              setPersonsAmount={ setPersonsAmount }
              personsAmount={ personsAmount }
              variant={ props.wrappable ? 'secondary' : 'tertiary' }
            />
            <FormDaypicker
              selectedDays={ selectedDays }
              onDayClick={ handleDayClick }
              variant={ props.wrappable ? 'secondary' : 'tertiary' }
              placeholder='Choose your date'
              setSelectedDays={ setSelectedDays }
            />
          </div>
          <div className={ st`button-holder` }>
            <Button className={ st`submit ${ props.wrappable && 'rounded' }` } type='submit' disabled={ appContext.loading }>
              <>
                { appContext.loading
                  ? <img src='/images/icons/search-throbber.svg' />
                  : <img src='/images/icons/search.svg' />
                }
                <span>Search</span>
              </>
            </Button>
          </div>
        </div>
        <div className={ st`mobile ${ expanded && st`expanded` }` }>
          <LocationSearchMobile
            selectedDays={ selectedDays }
            handleDayClick={ handleDayClick }
            setPersonsAmount={ setPersonsAmount }
            setSelectedDays={ setSelectedDays }
            personsAmount={ personsAmount }
            onSubmit={ startSearch }
            setExpanded={ (state) => setExpanded(state) }
            expanded={ expanded }
          />
        </div>
      </form>
      <button
        className={ st`overlay ${ !props.detailed && expanded && 'overlayVisible overlayBackground' }` }
        onClick={ () => { setExpanded(false); appContext.setHeaderVariant('default'); } }
        type="button"
      />
    </>
  );
}
