import { ReactElement, useState } from 'react';
import Stl from '@root/lib/Stl';
import steps from './Steps.module.scss';
const st = (new Stl()).bind(steps);

type Props = {
  imagePath: string;
  items: any;
  height: string;
  slides?: {
    slideIndex: number;
  };
}

export default function Steps(props: Props): ReactElement {
  const [ activeStep, setActiveStep ] = useState(1);
  const slidesIndex = props.slides?.slideIndex;

  const updateActiveStep = (event, step) => {
    event.preventDefault();
    setActiveStep(step + 1);
  };

  return (
    <div className={ st`grid` }>
      <div className={ st`media` }
        style={ { height: props.height } }
      >
        {props.items.map((item: any, index) =>
          <div
            key={ index }
            className={ st`image ${ index + 1 == activeStep && 'active' }` }
          >
            <img
              src={ `${ props.imagePath }${ (slidesIndex ?? index) + 1 }@1x.png` }
              srcSet={
                `${ props.imagePath }${ (slidesIndex ?? index) + 1 }@1x.png 1x, 
                ${ props.imagePath }${ (slidesIndex ?? index) + 1 }@2x.png 2x`
              }
              width="544px"
              height="464px"
            />
          </div>
        )}
      </div>

      <div className={ st`steps ${ props.slides && 'top' }` }>
        {props.items.map((item: any, index) =>
          <a 
            key={ index } 
            className={ st`step ${ activeStep == (index + 1) && 'active' }` } 
            href="#" 
            onClick={ (event) => updateActiveStep(event, index) }
          >
            <h5 className={ st`heading` }>
              <span className={ st`count` }>{(slidesIndex ?? index) + 1}</span>
              {item.heading}
            </h5>
            <div className={ st`content` }>
              <p>{item.content}</p>
            </div>
          </a>
        )}
      </div>
    </div>
  );
}
