import { ReactElement, useContext, useEffect, useState } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import { AuthContext } from '@context/AuthContext';
import { Button } from '@website/index';
import Link from 'next/link';

import Stl from '@root/lib/Stl';
import accountsignup from './AccountSignup.module.scss';
const st = (new Stl()).bind(accountsignup);

type Props = {
  status: number;
  message: string;
  onValidated: UnknownFunc;
  setCurrentStep: UnknownFunc;
}

export default function AccountSignupFormEmail(props: Props): ReactElement {
  const auth = useContext(AuthContext);

  // Create reference variables for input fields
  let email, password, first_name, last_name;

  const [ errorMessage, setErrorMessage ] = useState(null);
  const [ successMessage, setSuccessMessage ] = useState(null);
  const [ passwordConfirm, setPasswordConfirm ] = useState('');

  if (props.status === -1 && props.message && props.message !== errorMessage) {
    setErrorMessage(props.message);
  }

  if (props.status === 1 && props.message && props.message !== successMessage) {
    props.setCurrentStep('signup-verification');
  }

  const submitForm = (event) => {
    event.preventDefault();
    setErrorMessage(null);
    setSuccessMessage(null);

    props.onValidated({
      email: email.value,
      first_name: first_name.value,
      last_name: last_name.value,
      password: password.value,
    });
  };

  useEffect(() => {
    console.log(auth.signUpData);

    // Signed in successfully
    if (auth.signUpData && auth.signUpData.Users_Register) {
      props.setCurrentStep('signup-verification');
    }
  }, [ auth.signUpData ]);

  return (
    <form onSubmit={ submitForm }>
      <h4 className={ st`modalTitle` }>Create your account</h4>

      <div className={ st`formRow` }>
        <label className={ st`formRowLabel` } htmlFor="userName">First name</label>
        <input
          className={ st`formInput` }
          ref={ node => (first_name = node) }
          type="text"
          required
          placeholder="First name"
          id="userName"
        />
      </div>

      <div className={ st`formRow` }>
        <label className={ st`formRowLabel` } htmlFor="userLastName">Last name</label>
        <input
          className={ st`formInput` }
          ref={ node => (last_name = node) }
          type="text"
          required
          placeholder="Last name"
          id="userLastName"
        />
      </div>

      <div className={ st`formRow` }>
        <label className={ st`formRowLabel` } htmlFor="userEmail">Email address</label>
        <input
          className={ st`formInput` }
          ref={ node => (email = node) }
          type="email"
          required
          pattern="[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)"
          placeholder="Your email address"
          id="userEmail"
        />
      </div>

      <div className={ st`formRow` }>
        <label className={ st`formRowLabel` } htmlFor="userPassword">
          <span>Password</span>
        </label>
        <input
          className={ st`formInput` }
          ref={ node => (password = node) }
          type="password"
          required
          placeholder="Your password"
          id="userPassword"
        />
      </div>

      <div className={ st`formRow` }>
        <label className={ st`formRowLabel` } htmlFor="userConfirmPassword">
          <span>Confirm password</span>
        </label>
        <input
          className={ st`formInput` }
          type="password"
          required
          placeholder="Your password"
          name="PASSWORDCONFIRM"
          id="userConfirmPassword"
          value={ passwordConfirm }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => setPasswordConfirm(event.target.value) }
        />
        <PasswordStrengthBar className={ st`strengthBar` } password={ passwordConfirm } />
      </div>

      <div>
        { errorMessage &&
          <span className={ st`errorMessage` } dangerouslySetInnerHTML={ { __html: errorMessage } } />
        }
        { successMessage &&
          <span className={ st`successMessage` } dangerouslySetInnerHTML={ { __html: successMessage } } />
        }
      </div>

      <button
        type="submit"
        className={ st`button button--primary buttonWide` }
      >
        <span>Sign up</span>
      </button>

      { props.status !== 1 &&
        <>
          <div className={ st`formRow formFooter` }>
            <span>Already have an account? <Button variant="text" onClick={ () => props.setCurrentStep('login') }>Log in</Button></span>
          </div>
        </>
      }

      { props.status === 1 &&
        <div className={ st`formRow formRowLast` }>
          <div></div>
        </div>
      }
      <div className={ st`formRow formTerms` }>
        <span>
          By signing up you agree to YourDesq&apos;s<br />
          <Link href="/terms-and-conditions">
            <a target="_blank" className={ st`anchor` }>Terms and conditions</a>
          </Link> and acknowledge that YourDesq&apos;s <Link href="https://www.websitepolicies.com/policies/view/uLCIDWI5">
            <a className={ st`anchor` } target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          </Link> applies to you.
        </span>
      </div>
    </form>
  );
}
