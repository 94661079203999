/**
 * NOTE: Moved/deprecated use the button in '@core'
 */
import { ReactChildren, ReactElement, ReactNode } from 'react';
import Stl from '@root/lib/Stl';
import style from './Button.module.scss';
const st = (new Stl()).bind(style);

type Props = {
  variant?: 'primary' | 'secondary' | 'inverted' | 'transparent' | 'text' | 'error';
  children?: ReactNode|ReactNode[];
  onClick?: UnknownFunc;
  spaced?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
}

export default function Button(props: Props): ReactElement {
  return (
    <button
      className={ st`default ${ props.variant ? props.variant : 'primary' } ${ props.spaced && 'spaced' } ${ props.className }` }
      onClick={ (event) => { props.onClick ? props.onClick(event) : false; } }
      type={ props.type ? props.type : 'button' }
      disabled={ props.disabled }
    >
      { props.children }
    </button>
  );
}
