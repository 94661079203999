import { ReactElement, useRef } from 'react';
import Stl from '@root/lib/Stl';
import style from './Modal.module.scss';
const st = (new Stl()).bind(style);

import HostStripeConnect from './HostStripeConnect/HostStripeConnect';
import StripeConnectSuccess from './StripeConnectSuccess/StripeConnectSuccess';
import StripeConnectFailure from './StripeConnectFailure/StripeConnectFailure';
import Share from './Share/Share';

type Props = {
  modalVisible: boolean;
  setModalVisible: BoolVoidFunc;
  content: string;
};

const contents = {
  'HostStripeConnect': HostStripeConnect,
  'StripeConnectSuccess': StripeConnectSuccess,
  'StripeConnectFailure': StripeConnectFailure,
  'Share': Share,
};

export default function Modal(props: Props): ReactElement {
  const containerRef = useRef();

  const SelectedContent = contents[props.content];

  return (
    <>
      <div
        className={ st`modal-container ${ props.modalVisible && 'modal-container--is-visible' }` }
        ref={ containerRef }
        onClick={ event => {
          if (event.target === containerRef.current) {
            props.setModalVisible(false);
          }
        } }
      >
        <div className={ st`modal` }>
          <button
            onClick={ () => props.setModalVisible(false) }
            className={ st`modal-close-button` }
          >
            <img src="/images/icons/close.svg" />
          </button>
          <div className={ st`content` }>
            <SelectedContent
              setModalVisible={ props.setModalVisible }
            />
          </div>
        </div>
      </div>
    </>
  );
}
