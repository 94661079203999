import React, { useEffect, useState, useRef, useContext } from 'react';
import { AppContext } from '@context/AppContext';
import Stl from '@root/lib/Stl';

import style from './AutoComplete.module.scss';

const st = (new Stl()).bind(style);

type Props = {
  placeholder: string;
  searchIcon?: boolean;
  overlay?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary';
  onFocus?: UnknownFunc;
  hideDropdown?: boolean;
}

export default function AutoComplete(props: Props) {
  const appContext = useContext(AppContext);

  const [ searchValue, setSearchValue ] = useState<string>('');
  const [ dropdownVisible, setDropdownVisible ] = useState<boolean>(false);
  const [ searchTimer, setSearchTimer ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const [ suggestions, setSuggestions ] = useState<Array<Dict>>([]);
  const [ geocoder, setGeocoder ] = useState(null);

  const inputRef = useRef(null);
  // Run once
  useEffect(() => {
    if (process.browser && window.google) {
      setGeocoder(new window.google.maps.Geocoder());
    }

    if (appContext.userLocation && searchValue) {
      setSuggestions([]);
      setSearchValue('');
    }
  }, []);

  useEffect(() => {
    setDropdownVisible(props.hideDropdown);
  }, [ props.hideDropdown ]);

  // Sync local state when AppContext updates search results
  useEffect(() => {
    if (!appContext.userLocation) {
      setSearchValue(appContext.resultsLocation?.place?.structured_formatting?.main_text || '');
    }
  }, [ appContext.searchResults, appContext.userLocation ]);

  const execSearch = async (value) => {
    if (value.length < 1) {
      setSuggestions([]);

      return;
    }

    setDropdownVisible(true);

    const requestUri = `${ process.env.NEXT_PUBLIC_HOST_MIDDLEWARE }/locations/autocomplete?s=${ encodeURI(value) }`;

    const response = await fetch(requestUri);

    const data = await response.json();

    setLoading(false);

    setSuggestions(data.data && data.data ? data.data : []);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;

    setSearchValue(value);
    setLoading(!!value.length);
    appContext.setIsSearching(true);

    // Clear the timer and set a new search timer after 200ms
    searchTimer && clearTimeout(searchTimer);
    setSearchTimer(setTimeout(() => execSearch(value), 200));
  };

  const selectLocation = (location) => {
    setSearchValue(location.place?.structured_formatting.main_text || location.structured_formatting.main_text);
    appContext.disableUserLocation();
    appContext.setIsSearching(false);

    if (location.place) {
      appContext.setSelectedLocation(location);
      return;
    }

    appContext.setSelectedLocation({
      place: location,
      geocodeLoading: true,
    });

    geocoder.geocode({
      placeId: location.place_id,
    }, (results, status) => {
      appContext.setSelectedLocation((prevState) => {
        const newState: Dict = Object.assign({}, prevState);

        newState.geocodeLoading = false;

        if (!results) {
          return newState;
        }

        newState.geocode = results[0];

        newState.geocode.geometry.locationResolved = {
          lat: newState.geocode.geometry.location.lat(),
          lng: newState.geocode.geometry.location.lng(),
        };

        return newState;
      });
    });

    setDropdownVisible(false);
  };

  useEffect(() => {
    if (appContext.selectFirstSuggestion) {
      selectLocation(appContext.selectFirstSuggestion);
      setDropdownVisible(false);
      appContext.setSelectFirstSuggestion(false);
    }
  }, [ appContext.selectFirstSuggestion ]);

  useEffect(() => {
    if (!suggestions[0]) {
      return;
    }

    geocoder.geocode({
      placeId: suggestions[0].place_id,
    }, (results, status) => {
      if (!results || !results[0]) {
        return;
      }

      appContext.setSuggestions(() => {
        const newState: Dict = {
          place: suggestions[0],
        };

        newState.geocodeLoading = false;
        newState.geocode = results[0];

        newState.geocode.geometry.locationResolved = {
          lat: newState.geocode.geometry.location.lat(),
          lng: newState.geocode.geometry.location.lng(),
        };

        return [ newState ];
      });
    });
  }, [ suggestions ]);

  const renderSuggestionName = (suggestion) => {
    let highlightedName = suggestion.structured_formatting.main_text;

    const matchedString = highlightedName.substr(
      suggestion.matched_substrings[0].offset,
      suggestion.matched_substrings[0].length
    );

    highlightedName = highlightedName.replace(new RegExp(matchedString, 'gi'), (match) => `<span>${ match }</span>`);

    return (
      <div dangerouslySetInnerHTML={ { __html: highlightedName } } />
    );
  };

  const getPlaceholderText = () => {
    if (appContext.userLocation && !appContext.loadingUserLocation) {
      return 'Your location';
    }

    if (!appContext.userLocation && appContext.loadingUserLocation) {
      return 'Fetching location...';
    }
  };

  const closeDropDown = (event?) => {

    if (!event || (!event.target.closest(`.${ st`dropdown` }`)
        && !event.target.closest(`.${ st`input` }`))
        && !event.target.className.includes('LocationSearch')
        && !document.activeElement.className.includes('AutoComplete_input')
    ) {
      setDropdownVisible(false);
      document.body.removeEventListener('click', closeDropDown);
    }
  };

  const openDropDown = () => {
    setDropdownVisible(true);
    props.onFocus? props.onFocus(event) : null;
    document.body.addEventListener('click', closeDropDown);
  };

  return (
    <>
      <div className={ st`autocomplete ${ loading && 'autocomplete--loading' } ${ props.variant }` }>
        <input
          placeholder={ getPlaceholderText() || props.placeholder || 'Search cities' }
          className={ st`input
          ${ appContext.userLocation && 'input--user-location' }
          ${ (!appContext.userLocation && appContext.loadingUserLocation) && 'input--user-location-loading' }
          ${ props.searchIcon && 'search' }`
          }
          value={ searchValue }
          onChange={ handleSearchChange }
          onFocus={ (event) => {
            handleSearchChange(event);

            openDropDown(); props.onFocus? props.onFocus(event) : null;
          } }
          ref={ inputRef }
        />
        { (appContext.userLocation && !searchValue.length) &&
          <button
            className={ st`disable-user-location` }
            type="button"
            onClick={ () => { appContext.disableUserLocation(); inputRef.current.focus(); } }
          >
            <img src="/images/icons/close.svg" />
          </button>
        }
        <div className={ st`dropdown ${ ((dropdownVisible && !appContext.userLocation) || (dropdownVisible && searchValue.length)) && 'dropdown--is-visible' }` }>
          { !suggestions.length && (
            searchValue.length
              ? loading
                ? <span className={ st`dropdown-item dropdown-item--empty` }>
                  Loading...
                </span>
                : <span className={ st`dropdown-item dropdown-item--empty` }>
                  Nothing found
                </span>
              : !appContext.userLocation
                && <button
                  className={ st`dropdown-item dropdown-item--user-location` }
                  onClick={ appContext.requestUserLocation }
                  type="button"
                >
                  <img src="/images/icons/location-user--blue.svg" /> Search near your location
                </button>
          ) }
          { suggestions.map(suggestion => (
            <button
              key={ suggestion.place_id }
              className={ st`dropdown-item ${ appContext.selectedLocation?.place.place_id === suggestion.place_id && st`dropdown-item--active` }` }
              onClick={ () => selectLocation(suggestion) }
              type="button"
            >
              { renderSuggestionName(suggestion) }
            </button>
          )) }
        </div>
      </div>
      { props.overlay &&
        <button
          className={ st`overlay ${ ((dropdownVisible && !appContext.userLocation) || (dropdownVisible && searchValue.length)) && 'overlayVisible' } overlayBackground` }
          onClick={ () => setDropdownVisible(false) }
          type="button"
        />
      }
    </>
  );
}
