import { ReactElement, useEffect, useRef, useState, useContext } from 'react';
import { useRouter } from 'next/router';
import { AuthContext } from '@context/AuthContext';
import HeaderAccount from '@root/components/App/Header/AccountMenu';
import Link from 'next/link';
import { LocationSearch } from '../';
import Stl from '@root/lib/Stl';
import header from './Header.module.scss';
const st = (new Stl()).bind(header);

type Props = {
  variant?: 'primary' | 'secondary';
  buttonInverted?: boolean;
}

export default function Header(props: Props): ReactElement {
  const auth = useContext(AuthContext);
  const router = useRouter();
  const [ navigationVisible, setNavigationVisible ] = useState(false);

  const headerElement = useRef<HTMLElement>(null);

  useEffect(() => {
    if (process.browser) {
      window.addEventListener('scroll', scrollHandler);

      return () => {
        window.removeEventListener('scroll', scrollHandler);
      };
    }
  }, []);

  const scrollHandler = () => {
    if (!headerElement.current) {
      return;
    }

    if (window.scrollY > 104) {
      headerElement.current.classList.add(header[`isOpaque-${ props.variant || 'primary' }`]);
    } else {
      headerElement.current.classList.remove(header[`isOpaque-${ props.variant || 'primary' }`]);
    }
  };

  return (
    <>
      <header
        ref={ headerElement }
        className={ st`default ${ props.variant ? props.variant : 'primary' }` }>
        <div className={ st`container` }>
          <div className={ header.left }>
            <Link href='/'>
              <a className={ header.logo }>
                <img src='/images/logo.svg' />
              </a>
            </Link>
          </div>

          <div className={ st`center` }>
            <div className={ st`container` }>
              <LocationSearch />
            </div>
          </div>

          <div className={ st`right` }>
            { !auth.signedIn ? (
              <>
                <div className={ st`desktop-anchors` }>
                  <Link href="/about">
                    <a className={ st`text-anchor` }>About</a>
                  </Link>

                  <Link href="/become-a-host">
                    <a className={ st`text-anchor` }>Become a host</a>
                  </Link>

                  <Link href={ `${ router.route }?authModalStep=login` } shallow>
                    <a className={ st`button buttonInverted` }>Log in</a>
                  </Link>

                  <Link href={ `${ router.route }?authModalStep=login` } shallow>
                    <a className={ st`button primary` }>Sign up</a>
                  </Link>
                </div>

                <div className="mobileNavigation">
                  <button
                    className={ st`toggle ${ navigationVisible && 'toggleActive' }` }
                    onClick={ () => setNavigationVisible(!navigationVisible) }>
                    <div className={ st`toggleIcon` }></div>
                  </button>
                  <div className={ st`mobileDropdown ${ navigationVisible && 'visible' }` }>
                    <Link href={ `${ router.route }?authModalStep=login` } shallow>
                      <button className={ st`mobileDropdownButton` }>Sign up</button>
                    </Link>
                    <Link href={ `${ router.route }?authModalStep=login` } shallow>
                      <button className={ st`mobileDropdownButton` }>Log in</button>
                    </Link>
                    <div className={ st`mobileDropdownDivider` }></div>
                    <Link href="/about">
                      <button className={ st`mobileDropdownButton` }>About</button>
                    </Link>
                    <Link href="/become-a-host">
                      <button className={ st`mobileDropdownButton` }>Become a host</button>
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <>
                { auth.accountCookie?.isHost ? (
                  <Link href="/dashboard/listings">
                    <a className={ st`text-anchor secondary` }>Create your listing</a>
                  </Link>
                ) : (
                  <Link href="/become-a-host">
                    <a className={ st`text-anchor secondary` }>Start hosting</a>
                  </Link>
                )}
                <HeaderAccount />
              </>
            ) }
          </div>
        </div>
      </header>
      {/* <button
        className={ st`overlay ${ navigationVisible && 'overlayVisible' }` }
        onClick={ () => setNavigationVisible(false) }
      /> */}
    </>
  );
}
