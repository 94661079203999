import { ReactElement } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import NewsletterSignupForm from '../Form/NewsletterSignupForm/NewsletterSignupForm';

import Stl from '@root/lib/Stl';
import stickymodal from './StickyModal.module.scss';
const st = (new Stl()).bind(stickymodal);

type Props = {
  maxWidthStyle?: 'modalSmall';
  modalVisible: boolean;
  setModalVisible: BoolVoidFunc;
  name: string;
}

export default function StickyModal(props: Props): ReactElement {
  const closeModal = () => {
    window.localStorage.setItem(props.name, '1');
    props.setModalVisible(false);
  };

  return (
    <div className={ st`modal ${ props.maxWidthStyle } ${ props.modalVisible ? 'modal--is-visible' : 'modal--is-hidden' }` }>
      <button
        onClick={ closeModal }
        className={ st`modalClose` }
      >
        <img src="/images/icons/close.svg" />
      </button>
      <MailchimpSubscribe
        url="//yourdesq.us7.list-manage.com/subscribe/post?u=03bea35e4d00a8c48d5a0569f&amp;id=e530e0082d"
        render={ ({ subscribe, status, message }) => (
          <NewsletterSignupForm
            status={ status }
            message={ message }
            onValidated={ formData => subscribe(formData) }
          />
        ) }
      />
    </div>
  );
}
