import React, { ReactElement, useState } from 'react';
import Stl from '@root/lib/Stl';
import style from './AccountMenu.module.scss';
import Link from 'next/link';

const st = (new Stl()).bind(style);

export default function AdminAccountSubmenu(): ReactElement {
  const [ accountListVisible, setAccountListVisible ] = useState(true);

  return (
    <div className={ st`hostSettingsSubmenu ${ accountListVisible && 'hostSettingsVisible' }` }>
      <div className={ st`accountListDivider` }></div>
      <button
        className={ st`accountButton` }
        onClick={ (event) => { event.stopPropagation(); setAccountListVisible(!accountListVisible); } }
      >
        <span>ADMIN</span>
        <img className={ st`accountButtonIcon` } src="/images/icons/chevron-up.svg" />
      </button>
      <div>
        <div className={ st`wrapper` }>
          <Link href="/dashboard/admin/listings">
            <button className={ st`accountListButton` }><span>All listings</span></button>
          </Link>
          <Link href="/dashboard/admin/users">
            <button className={ st`accountListButton` }><span>All users</span></button>
          </Link>
          <Link href="/dashboard/admin/statistics">
            <button className={ st`accountListButton` }><span>Statistics</span></button>
          </Link>
        </div>
      </div>
    </div>
  );
}
