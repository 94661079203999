import { ReactElement } from 'react';
import Stl from '@root/lib/Stl';
import style from './ListingStatusBadge.module.scss';

const st = (new Stl()).bind(style);

interface Props {
  status: string;
}

export default function ListingCard(props: Props): ReactElement {
  if (!props.status) {
    return null;
  }

  const statusStrings = {
    active: {
      label: 'published',
      long: 'Published',
    },
    disabled: {
      label: 'draft',
      long: 'Draft',
    },
    deleted: {
      label: 'deleted',
      long: 'Deleted',
    },
    archived: {
      label: 'archived',
      long: 'Archived',
    },
  };

  const statusString = statusStrings[props.status];

  return (
    <div className={ st`badge ${ `badge--${ statusString.label }` }` }>{ statusString.long }</div>
  );
}
