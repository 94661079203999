import { ReactElement, useContext } from 'react';
import { AuthContext } from '@context/AuthContext';
import Stl from '@root/lib/Stl';
import style from './HostStripeConnect.module.scss';
import Button from '../../Button/Button';
const st = (new Stl()).bind(style);

export default function HostStripeConnect(): ReactElement {
  const auth = useContext(AuthContext);

  const getStripeLink = async () => {
    return await fetch(`${ process.env.NEXT_PUBLIC_HOST_MIDDLEWARE }/transactions/request?user_id=${ auth.account.id }`);
  };

  const openStripeLink = async () => {
    const response = await getStripeLink();

    if (!response.ok) {
      return;
    }

    const responseData = await response.json();

    if (responseData.data) {
      window.location.href = responseData.data;
    }
  };

  return (
    <div className={ st`container` }>
      <h4>Connect your account to Stripe to start hosting</h4>
      <p>We use Stripe to securely receive payments and transfer money to hosts.</p>
      <Button
        onClick={ openStripeLink }
      >
        Connect your account
      </Button>
    </div>
  );
}
