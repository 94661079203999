class Stl {
  private style: Dict;
  private warn: boolean;

  bind(styleObj: Dict, warn = false): UnknownFunc {
    this.style = styleObj;
    this.warn = warn;

    return this.map.bind(this);
  }

  map(strings, ...expressions): string {
    strings = strings.concat(expressions).filter(string => !!string);

    return strings.join(' ').split(/\s+/).reduce((res, part) => {
      const partCleaned = part.trim();

      if (!partCleaned.length) {
        return res;
      }

      if (this.warn && !this.style[partCleaned]) {
        console.warn(`Class "${ part }" is not part of the CSS module loaded.`);
      }

      return `${ res ? `${ res } ` : res }${ this.style[partCleaned] || partCleaned }`;
    }, '');
  }
}

export default Stl;
