import React, { ReactChild, ReactElement, useContext } from 'react';
/** Context */
import { ModalsContext } from '@root/context/ModalsContext';
/** Components */
import Button from '@core/Button/Button';
/** Styling */
import style from '@core/ModalDialog/ModalDialog.module.scss';
import Stl from '@root/lib/Stl';
const st = (new Stl()).bind(style);

interface Props {
  children: ReactChild[]|ReactChild,
  scrollable?: boolean,
  titleAlignment?: 'left' | 'center'
  actionButtons?: ReactElement // Buttons to show in the top right next to the close button
  closeButtonType?: 'x' | 'close'
}

export default function ModalDialog({
  children,
  scrollable,
  titleAlignment,
  actionButtons,
  closeButtonType,
}: Props): ReactElement {
  const modalContext = useContext(ModalsContext);
  return (
    <>
      <div className={ st`modal-dialog no-print` }>
        <div
          className={ st`modal-dialog__overlay` }
          onClick={ () => modalContext.close() } />
        <div className={ st`modal-dialog__container` }>
          <div className={ 
            st`modal-dialog__container__top ${ scrollable ? 'modal-dialog__container__top--shadow' : '' }`
          }>
            <div className={ st`modal-dialog__container__title ${ titleAlignment === 'left' ? 'modal-dialog__container__title--left' : '' }` }>
              { modalContext.title }
            </div>
            { actionButtons }
            { closeButtonType === 'x' && 
              <button
                className={ st`modal-dialog__container__close` }
                onClick={ () => modalContext.close() }>
                <img src="/images/icons/close.svg" />
              </button>
            }
            { closeButtonType === 'close' && 
              <Button
                className={ st`modal-dialog__container__close` }
                variant="inverted"
                type="button"
                onClick={ () => modalContext.close()  }
              >
                Close
              </Button>
            }
          </div>
          <div className={ 
            st`modal-dialog__container__body ${ scrollable ? 'modal-dialog__container__body--scrollable' : '' }` 
          }>
            { children }
            {/* TODO: Create generic control panel for all types of footer */}
          </div>
        </div>
      </div>
      <div className={ st`print-only` }>{ children }</div>
    </>
  );
}
