import { createContext, ReactChild, ReactElement, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

interface Props {
  children: ReactChild[]|ReactChild
}

export const BasketContext = createContext<any>(null);

interface BasketItemOptions {
  personsAmount: number;
  date: string;
  listing: Dict;
  workspace: Dict;
  quantity: number;
}

export class BasketItem {
  public personsAmount: number;
  public listing: Dict;
  public date: string;
  public workspace: Dict;
  public quantity: number;

  constructor(options: BasketItemOptions) {
    this.personsAmount = options.personsAmount;
    this.listing = options.listing;
    this.date = options.date;
    this.workspace = options.workspace;
    this.quantity = options.quantity;
  }
}

export default function CookiesProvider({ children }: Props): ReactElement {
  const router = useRouter();
  const [ basketItems, setBasketItems ] = useState(null);

  // Run once
  useEffect(() => {
    if (process.browser) {
      if (!basketItems && localStorage.getItem('basketItems')) {
        setBasketItems(JSON.parse(localStorage.getItem('basketItems')));
      }
    }
  }, []);

  const goToCheckout = () => {
    router.push('/checkout');
  };

  const contextValue = {
    basketItems,
    setBasketItems,
    goToCheckout,
  };

  useEffect(() => {
    localStorage.setItem('basketItems', JSON.stringify(basketItems));
  }, [ basketItems ]);

  return (
    <BasketContext.Provider value={ contextValue }>
      { children }
    </BasketContext.Provider>
  );
}
