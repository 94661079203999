import { ReactElement, useContext } from 'react';
import { AppContext } from '@context/AppContext';
import Stl from '@root/lib/Stl';
import { Button } from '../';
import ListingStatusBadge from '@app/Dashboard/Listings/ListingStatusBadge';

import header from './Header.module.scss';
const st = (new Stl()).bind(header);

export default function DashboardListingsHeader(): ReactElement  {
  const appContext = useContext(AppContext);

  return (
    <div className={ st`right` }>
      <div className={ st`left-buttons` }>
        <ListingStatusBadge
          status={ appContext.currentListingEdited?.status.value }
        />
        <Button
          variant="secondary"
          disabled={ !appContext.canSaveListing }
          onClick={ () => appContext.setTriggerSaveExit('save') }
        >
          Save
        </Button>
        {appContext.currentListingEdited?.status.value !== 'active' && (
          <Button
            variant="primary"
            disabled={ !appContext.canPublishListing }
            onClick={ () => appContext.setTriggerSaveExit('publish') }
          >
            Publish
          </Button>
        )}
        {appContext.currentListingEdited?.status.value !== 'archived'
          && appContext.currentListingEdited?.status.value !== 'disabled' && (
          <Button
            variant="secondary"
            onClick={ () => appContext.setTriggerSaveExit('archive') }
          >
              Archive
          </Button>
        )}
        <Button
          variant="transparent"
          onClick={ () => appContext.setTriggerSaveExit('exit') }
        >
          Exit
        </Button>
      </div>
      <div className={ st`left-mobileButtons` }>
        <Button
          variant="transparent"
          onClick={ () => {
            appContext.setTriggerSaveExit('save');
            appContext.setTriggerSaveExit('exit');
          } }
        >
          Save and exit
        </Button>
      </div>
    </div>
  );
}
