import { ReactElement, useEffect, useRef } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import NewsletterSignupForm from '../Form/NewsletterSignupForm/NewsletterSignupForm';
import UserSignupForm from '../Form/UserSignupForm/UserSignupForm';
import HostSignupForm from '../Form/HostSignupForm/HostSignupForm';

import Stl from '@root/lib/Stl';
import modal from './Modal.module.scss';
const st = (new Stl()).bind(modal);

type Props = {
  maxWidthStyle?: 'modalSmall';
  modalVisible: boolean;
  setModalVisible: BoolVoidFunc;
  selectedForm: 'NewsletterSignupForm' | 'UserSignupForm' | 'HostSignupForm';
}

export default function Modal(props: Props): ReactElement {
  const modalRef = useRef();

  useEffect(() => {
    if (props.modalVisible) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'auto';
    }
  }, [ props.modalVisible ]);

  const forms = {
    NewsletterSignupForm: {
      form: NewsletterSignupForm,
      url: '//yourdesq.us7.list-manage.com/subscribe/post?u=03bea35e4d00a8c48d5a0569f&amp;id=e530e0082d',
    },
    UserSignupForm: {
      form: UserSignupForm,
      url: '//yourdesq.us7.list-manage.com/subscribe/post?u=03bea35e4d00a8c48d5a0569f&amp;id=5eb0db5034',
    },
    HostSignupForm: {
      form: HostSignupForm,
      url: '//yourdesq.us7.list-manage.com/subscribe/post?u=03bea35e4d00a8c48d5a0569f&amp;id=269a93216e',
    },
  };

  const SelectedForm: typeof NewsletterSignupForm | typeof UserSignupForm = forms[props.selectedForm].form;
  const selectedUrl: string = forms[props.selectedForm].url;

  return (
    <div
      ref={ modalRef }
      onClick={ event => {
        if (event.target === modalRef.current) {
          props.setModalVisible(false);
        }
      } }
      className={ st`modalContainer ${ props.modalVisible && 'modalContainerVisible' }` }
    >
      <div className={ st`modal ${ props.maxWidthStyle }` }>
        <button
          onClick={ () => props.setModalVisible(false) }
          className={ st`modalClose` }
        >
          <img src="/images/icons/close.svg" />
        </button>
        <MailchimpSubscribe
          url={ selectedUrl }
          render={ ({ subscribe, status, message }) => (
            <SelectedForm
              status={ status }
              message={ message }
              onValidated={ formData => subscribe(formData) }
              setModalVisible={ props.setModalVisible }
            />
          ) }
        />
      </div>
    </div>
  );
}
