import { ReactElement } from 'react';
import { Button } from '../..';

import Stl from '@root/lib/Stl';
import accountsignup from './AccountSignup.module.scss';
import Link from 'next/link';
const st = (new Stl()).bind(accountsignup);

type Props = {
  status: string;
  onValidated: UnknownFunc;
  setCurrentStep: UnknownFunc;
}

export default function AccountSignupForm(props: Props): ReactElement {
  return (
    <>
      <h4 className={ st`modalTitle` }>Create your account</h4>

      {/* <button
        className={ st`button button--ghost buttonWide` }
        onClick={ () => console.log('Sign up with Google') }
      >
        <img src="/images/icons/google.svg" />
        <span>Sign up with Google</span>
      </button> */}

      <button
        className={ st`button button--ghost buttonWide` }
        onClick={ () => props.setCurrentStep('signup-email') }
      >
        <img src="/images/icons/email.svg" />
        <span>Sign up with email</span>
      </button>

      { props.status !== 'success' &&
        <>
          <div className={ st`formRow formFooter` }>
            <span>Already have an account? <Button variant="text" onClick={ () =>props.setCurrentStep('login') }>Log in</Button></span>
          </div>
          <div className={ st`formRow formTerms` }>
            <span>
              By clicking signin up you agree to Yourdesq’s<br />
              <Link href="/terms-and-conditions">
                <a className={ st`anchor` }>Terms and conditions</a>
              </Link> and acknowledge that Yourdesq’s <Link href="https://www.websitepolicies.com/policies/view/uLCIDWI5">
                <a className={ st`anchor` } target="_blank" rel="noopener noreferrer">Privacy Policy</a>
              </Link> applies to you.
            </span>
          </div>
          {/* <div className={ st`formRow formFooter` }>
            <Button variant="text" onClick={ () => props.setCurrentStep('signup-verification') }>To signup verification (DEMO)</Button>
          </div>
          <div className={ st`formRow formFooter` }>
            <Button variant="text" onClick={ () => props.setCurrentStep('signup-verification-success') }>To signup verification success (DEMO)</Button>
          </div> */}
        </>
      }

      { props.status === 'success' &&
        <div className={ st`formRow formRowLast` }>
          <div></div>
        </div>
      }
    </>
  );
}
