import { ReactElement } from 'react';
import Stl from '@root/lib/Stl';
import style from './StripeConnectFailure.module.scss';
const st = (new Stl()).bind(style);

export default function StripeConnectFailure(): ReactElement {

  return (
    <div className={ st`container` }>
      <h4>Error while connecting your account</h4>
      <p>There was an error while trying to finalize your Stripe connection. Please contact <a href="mailto:hello@yourdesq.com">YourDesq</a> for support.</p>
    </div>
  );
}
