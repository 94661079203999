import { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '@context/AppContext';
import { ModalsContext } from '@root/context/ModalsContext';
import { useRouter } from 'next/router';
import Stl from '@root/lib/Stl';

import header from './Header.module.scss';
import DashboardListingsHeader from './DashboardListingsHeader';
import MainHeader from './MainHeader';
import ListingsDetailHeader from './ListingDetailHeader';
const st = (new Stl()).bind(header);

export default function Header(): ReactElement {
  const router = useRouter();
  const modalContext = useContext(ModalsContext);
  const appContext = useContext(AppContext);

  const [ navigationVisible, setNavigationVisible ] = useState(false);
  const [ mainHeaderHidden, setMainHeaderHidden ] = useState(false);

  const toggleDropDown = () => {
    if (!navigationVisible) {
      document.body.addEventListener('click', closeDropDown);
    }

    setNavigationVisible(!navigationVisible);
  };

  const closeDropDown = (event?) => {
    if (!event || (!event.target.closest(`.${ st`toggle` }`))) {
      setNavigationVisible(false);
      document.body.removeEventListener('click', closeDropDown);
    }
  };

  const scrollHandler = useCallback(() => {
    if(router.route === '/listings/[uuid]') {
      if (window.scrollY >= 560) {
        setMainHeaderHidden(true);
      } else {
        setMainHeaderHidden(false);
      }
    }
  }, [ router.pathname ]);

  useEffect(() => {
    // TODO: Optimize this to only add listener on the homepage
    window.addEventListener('scroll', scrollHandler);
    scrollHandler();

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [ router.pathname ]);

  return (
    <header
      className={
        st`default 
          ${ appContext.headerVariant !== 'clear'
          && appContext.headerVariant !== 'simple'
          && 'default--has-search'
          && modalContext.modalVisibleShare && 'hidden' }`
      }
    >
      <div className={ st`container` }>

        { !mainHeaderHidden &&
          <MainHeader navigationVisible={ navigationVisible } toggleDropDown={ toggleDropDown } />
        }

        {router.asPath.indexOf('/dashboard/listings/') === 0 && (
          <DashboardListingsHeader />
        )}

        {router.route === '/listings/[uuid]' && mainHeaderHidden &&
          <ListingsDetailHeader />
        }
      </div>
    </header>
  );
}
  
