import { gql } from '@apollo/client';

// GQL Queries
export const AUTH_SIGN_IN = gql`
  query ($email: String!, $password: String!) {
    Users_Signin(email: $email, password: $password) {
      id
      uuid
      email
      email_verified
      first_name
      image
      last_name
      updated_at
      stripe_user_id
      is_admin
      jwt
    }
  }
`;

export const AUTH_SIGN_UP = gql`
  mutation ($email: String!, $password: String!, $first_name: String!, $last_name: String!) {
    Users_Register(email: $email, password: $password, first_name: $first_name, last_name: $last_name) {
      token
    }
  }
`;

export const AUTH_VERIFY = gql`
  mutation ($token: String!) {
    Users_Verify(token: $token) {
      affected_rows
    }
  }
`;

export const AUTH_REFRESH = `
  query ($email: String!) {
    users(where: {email: {_eq: $email}}) {
      id
      image
      uuid
      email
      first_name
      status
      last_name
      updated_at
      is_admin
      stripe_user_id
    }
  }
`;

export const AUTH_RESTORE_PASSWORD = gql`
  mutation ($email: String! $link: String) {
    Users_Restore_Password_Request(email: $email, link: $link) {
      status
    }
  }
`;

export const AUTH_CHANGE_PASSWORD = gql`
  mutation ($password: String!, $token: String!) {
    Users_Restore_Password (
      password: $password
      token: $token
    ) {
      status
    }
  }
`;

export const AUTH_IS_LISTING_MANAGER = `
query ($user_id: Int!) {
  listings_managers(where: {user_id: {_eq: $user_id}, status: {_eq: "active"}}, limit: 1) {
    id
  }
}
`;
