import { createContext, ReactChild, ReactElement, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { CookiesContext } from '@context/CookiesContext';

import Modal from '@website/Modal/Modal';
import AppModal from '@app/Modal/Modal';
import ModalSignin from '@website/Modal/Signin';
import StickyModal from '@website/StickyModal/StickyModal';
import CookieNotification from '@website/CookieNotification/CookieNotification';
import SuccessNotification from '@root/components/App/SuccessNotification/SuccessNotification';
import ModalDialog from '@core/ModalDialog/ModalDialog';
import Transition from '@core/Transition/Transition';

interface Props {
  children: ReactChild[]|ReactChild
}

export const ModalsContext = createContext<any>(null);

export default function ModalsProvider({ children }: Props): ReactElement {
  const router = useRouter();
  const cookies = useContext(CookiesContext);

  const [ internalAuthModalStep, setInternalAuthModalStep ] = useState(null);
  const [ modalVisibleSignin, setModalVisibleSignin ] = useState(null);
  const [ modalVisibleNewsletter, setModalVisibleNewsletter ] = useState(null);
  const [ modalVisibleUserSignup, setModalVisibleUserSignup ] = useState(null);
  const [ modalVisibleHostSignup, setModalVisibleHostSignup ] = useState(null);
  const [ modalVisibleStripeConnect, setModalVisibleStripeConnect ] = useState(null);
  const [ modalVisibleStripeConnectSuccess, setModalVisibleStripeConnectSuccess ] = useState(null);
  const [ modalVisibleStripeConnectFailure, setModalVisibleStripeConnectFailure ] = useState(null);
  const [ stickyModalVisibleNewsletter, setStickyModalVisibleNewsletter ] = useState(null);
  const [ cookieNotificationVisible, setCookieNotificationVisible ] = useState(null);
  const [ successNotification, setSuccessNotification ] = useState(null);
  const [ modalVisibleShare, setModalVisibleShare ] = useState(null);

  // Generic modal states
  const [ visibility, setVisibility ] = useState(false);
  const [ title, setTitle ] = useState('');
  const [ modalBody, setModalBody ] = useState(<></>);
  const [ scrollable, setScrollable ] = useState(false);
  const [ titleAlignment, setTitleAlignment ] = useState<'center' | 'left'>('center');
  const [ actionButtons, setActionButtons ] = useState(<></>);
  const [ closeButtonType, setCloseButtonType ] = useState<'x' | 'close'>('x');

  const availableRoutes = [
    'login',
    'signup',
    'verify',
    'reset',
  ];

  // This method doesn't seem to work reliably on initial pageload in dev.
  // On initial pageload the "query" param in router is empty
  let authModalStep = router.query.auth ? router.query.auth : '';

  if (typeof router.query.auth !== 'string') {
    authModalStep = authModalStep[0];
  }

  const routeIsValid = availableRoutes.some(route => route.includes(authModalStep as string));

  const contextValue = {
    // Generic modal
    open: () => {
      setVisibility(true);
    },
    close: () => {
      setVisibility(false);
    },
    setModalBody,
    title,
    setTitle,
    setScrollable,
    titleAlignment,
    setTitleAlignment,
    actionButtons,
    setActionButtons,
    closeButtonType,
    setCloseButtonType,

    modalVisibleSignin,
    setModalVisibleSignin,

    modalVisibleNewsletter,
    setModalVisibleNewsletter,

    modalVisibleUserSignup,
    setModalVisibleUserSignup,

    modalVisibleHostSignup,
    setModalVisibleHostSignup,

    stickyModalVisibleNewsletter,
    setStickyModalVisibleNewsletter,

    cookieNotificationVisible,
    setCookieNotificationVisible,

    successNotification,
    setSuccessNotification,

    modalVisibleStripeConnect,
    setModalVisibleStripeConnect,

    modalVisibleStripeConnectSuccess,
    setModalVisibleStripeConnectSuccess,

    modalVisibleStripeConnectFailure,
    setModalVisibleStripeConnectFailure,

    setInternalAuthModalStep,

    modalVisibleShare,
    setModalVisibleShare,

    routeIsValid,
  };

  useEffect(() => {
    if (process.browser) {
      if (!window.localStorage.getItem('cookies__accepted')) {
        setCookieNotificationVisible(true);
      } else {
        cookies.setCookiesAccepted(true);
      }
    }
  }, []);

  useEffect(() => {
    router?.events?.on('beforeHistoryChange', (route: string) => {
      if(process.browser){
        if (!window.localStorage.getItem('newsletter-notification__closed')) {
          setStickyModalVisibleNewsletter(true);
        }
      }
    });
  }, [ cookieNotificationVisible ]);

  useEffect(() => {
    if (authModalStep && routeIsValid) {
      setModalVisibleSignin(true);
    }

    if (router.asPath === '/createaccount' && !modalVisibleSignin) {
      setInternalAuthModalStep('signup');
      setModalVisibleSignin(true);
    }
  }, [ router.asPath, authModalStep, routeIsValid ]);

  return (
    <ModalsContext.Provider value={ contextValue }>
      { children }

      <Transition
        appear={ true }
        in={ visibility }
        type='fade'
        unmountOnExit>
        <ModalDialog
          scrollable={ scrollable }
          titleAlignment={ titleAlignment }
          closeButtonType= { closeButtonType }
          actionButtons= { actionButtons }
        >
          { modalBody }
        </ModalDialog>
      </Transition>

      { !cookieNotificationVisible &&
        <StickyModal
          modalVisible={ stickyModalVisibleNewsletter }
          setModalVisible={ setStickyModalVisibleNewsletter }
          name="newsletter-notification__closed"
        />
      }

      <CookieNotification
        modalVisible={ cookieNotificationVisible }
        setModalVisible={ setCookieNotificationVisible }
        setCookiesAccepted={ cookies.setCookiesAccepted }
        name="cookies__accepted"
      />

      <Modal
        modalVisible={ modalVisibleNewsletter }
        setModalVisible={ setModalVisibleNewsletter }
        selectedForm="NewsletterSignupForm"
      />

      <Modal
        modalVisible={ modalVisibleUserSignup }
        setModalVisible={ setModalVisibleUserSignup }
        selectedForm="UserSignupForm"
        maxWidthStyle="modalSmall"
      />

      <Modal
        modalVisible={ modalVisibleHostSignup }
        setModalVisible={ setModalVisibleHostSignup }
        selectedForm="HostSignupForm"
        maxWidthStyle="modalSmall"
      />

      <AppModal
        modalVisible={ modalVisibleShare }
        setModalVisible={ setModalVisibleShare }
        content="Share"
      />

      <AppModal
        modalVisible={ modalVisibleStripeConnect }
        setModalVisible={ setModalVisibleStripeConnect }
        content="HostStripeConnect"
      />

      <AppModal
        modalVisible={ modalVisibleStripeConnectSuccess }
        setModalVisible={ setModalVisibleStripeConnectSuccess }
        content="StripeConnectSuccess"
      />

      <AppModal
        modalVisible={ modalVisibleStripeConnectFailure }
        setModalVisible={ setModalVisibleStripeConnectFailure }
        content="StripeConnectFailure"
      />

      <ModalSignin
        modalVisible={ (modalVisibleSignin && (authModalStep !== undefined || internalAuthModalStep !== undefined)) }
        setModalVisible={ setModalVisibleSignin }
        currentStep={ internalAuthModalStep || authModalStep as string }
      />

      <SuccessNotification
        modalVisible={ successNotification?.modalVisible }
        setModalVisible={ setSuccessNotification }
        content={ successNotification?.content }
      />
    </ModalsContext.Provider>
  );
}
