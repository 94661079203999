import { Component, ReactElement, ReactPropTypes, Fragment, useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import Head from 'next/head';

import { useApollo } from '@root/lib/apolloClient';

import SwiperCore, { Navigation } from 'swiper';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);

import AppProvider from '@context/AppContext';
import AuthProvider from '@context/AuthContext';
import CookiesProvider from '@context/CookiesContext';
import AnalyticsProvider from '@context/AnalyticsContext';
import ModalsProvider from '@root/context/ModalsContext';
import BasketProvider from '@root/context/BasketContext';

import BaseHead from '@root/components/Global/BaseHead';

import '@root/styles/index.scss';

// install Swiper modules
SwiperCore.use([ Navigation ]);
interface Props {
  Component: typeof Component|any;
  pageProps: ReactPropTypes|any;
}

export default function YourDesqApp({ Component, pageProps }: Props): ReactElement {
  const apolloClient = useApollo(pageProps.initialApolloState);

  const PageLayout = Component.Layout ? Component.Layout : Fragment;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.logEnv = () => {
        console.info('ENV vars:');
        console.info('NEXT_PUBLIC_HOST', process.env.NEXT_PUBLIC_HOST);
        console.info('NEXT_PUBLIC_HOST_GRAPHQL', process.env.NEXT_PUBLIC_HOST_GRAPHQL);
        console.info('NEXT_PUBLIC_HOST_MIDDLEWARE', process.env.NEXT_PUBLIC_HOST_MIDDLEWARE);
        console.info('NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY', process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);
      };
    }

    if (process.env.NEXT_PUBLIC_ENV !== 'production') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.logEnv();
    }
  }, []);

  return (
    <ApolloProvider client={ apolloClient }>
      <AppProvider>
        <CookiesProvider>
          <AnalyticsProvider>
            <AuthProvider>
              <Elements stripe={ stripePromise }>
                <BasketProvider>
                  <Head>
                    <BaseHead />
                  </Head>

                  <ModalsProvider>
                    <PageLayout>
                      <Component
                        { ...pageProps }
                      />
                    </PageLayout>
                  </ModalsProvider>
                </BasketProvider>
              </Elements>
            </AuthProvider>
          </AnalyticsProvider>
        </CookiesProvider>
      </AppProvider>
    </ApolloProvider>
  );
}
