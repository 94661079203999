import { ReactElement, useContext, useRef, useState } from 'react';
import Link from 'next/link';
import Stl from '@root/lib/Stl';
import { AuthContext } from '@context/AuthContext';
import buttonStyle from '@app/Button/Button.module.scss';
import style from './AccountMenu.module.scss';
import HostAccountSubmenu from './HostAccountSubmenu';
import AdminAccountSubmenu from './AdminAccountSubmenu';

const st = (new Stl()).bind(style);

type Props = {
  variant?: 'primary' | 'secondary'
};

export default function HeaderAccountMenu(props: Props): ReactElement {
  const auth = useContext(AuthContext);
  const menuButtonRef = useRef(null);

  const [ accountListVisible, setAccountListVisible ] = useState(false);

  const openMenu = () => {
    document.body.addEventListener('click', closeMenu);
    setAccountListVisible(true);
  };

  const closeMenu = (event?) => {
    if (!event || event.target.classList.contains(`.${ st`accountListButton` }`) || event.target.closest(`.${ style.accountButton }`) !== menuButtonRef.current) {
      setAccountListVisible(false);
      document.body.removeEventListener('click', closeMenu);
    }
  };

  const toggleMenu = () => {
    accountListVisible ? closeMenu() : openMenu();
  };

  return (
    <>
      <div
        className={ st`account ${ props.variant || 'primary' } ${ accountListVisible && 'accountVisible' } no-print` }
      >
        <button
          ref={ menuButtonRef }
          className={ style.accountButton }
          onClick={ toggleMenu }
        >
          <div className={ st`avatar` } style={ { backgroundImage: 'url(/images/avatar-blank.svg)' } }>
            {/* <div className={ st`badge` }>8</div> */}
          </div>
          <span>{auth.account?.first_name}</span>
          <img className={ st`accountButtonIcon` } src="/images/icons/chevron-down.svg" />
        </button>

        <div className={ st`accountList` }>
          {/* <Link href="/dashboard/inbox">
            <button className={ st`accountListButton` }><span className={ st`accountListButtonBadge` }>Inbox</span></button>
          </Link> */}
          <Link href="/dashboard/bookings">
            <button className={ st`accountListButton` }><span>Your bookings</span></button>
          </Link>
          <Link href="/dashboard/billing">
            <button className={ st`accountListButton` }><span>Billing</span></button>
          </Link>
          {auth.account?.isHost &&
            <HostAccountSubmenu />
          }

          {auth.account?.is_admin &&
            <AdminAccountSubmenu />
          }
          <div className={ st`accountListDivider` }></div>
          <Link href="/about">
            <button className={ st`accountListButton` }><span>About</span></button>
          </Link>

          <Link href="/dashboard/settings">
            <button className={ st`accountListButton` }><span>Account settings</span></button>
          </Link>

          <Link href={ '/?authModalStep=logout' } as={ '/' }>
            <button className={ st`accountListButton` }><span>Log out</span></button>
          </Link>
          {!auth.account?.isHost &&
            <>
              <Link href="/become-a-host">
                <button className={ st`actionButton ${ buttonStyle.default } ${ buttonStyle.inverted }` } >Become a host</button>
              </Link>
              <Link href="/listings">
                <button className={ st`actionButton ${ buttonStyle.default } ${ buttonStyle.primary }` } >Find a workspace</button>
              </Link>
            </>
          }
          {auth.account?.isHost &&
            <Link href="/dashboard/listings">
              <button className={ st`actionButton ${ buttonStyle.default } ${ buttonStyle.primary }` } type="button">
                Create your listing
              </button>
            </Link>
          }

        </div>
      </div>
    </>
  );
}
