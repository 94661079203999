import { ReactElement, useContext, useState } from 'react';
import { Button } from '../..';

import { AuthContext } from '@context/AuthContext';

import Stl from '@root/lib/Stl';
import passwordreset from './PasswordReset.module.scss';
import { ModalsContext } from '@root/context/ModalsContext';

const st = (new Stl()).bind(passwordreset);

type Props = {
  status: string;
  onValidated: UnknownFunc;
  setCurrentStep?: UnknownFunc;
  stepFromUpdate?: boolean;
}

export default function FormsPasswordResetRequest(props: Props): ReactElement {
  const auth = useContext(AuthContext);
  const modal = useContext(ModalsContext);

  // Create reference variables for input fields
  let email;

  const [ errorMessage, setErrorMessage ] = useState(null);
  const [ successMessage, setSuccessMessage ] = useState(null);

  if (props.status === 'error') {
    setErrorMessage('');
  }

  if (props.status === 'success') {
    setSuccessMessage('');
  }

  const submitForm = async (event) => {
    event.preventDefault();

    if (!email) {
      return;
    }

    setErrorMessage(null);
    setSuccessMessage(null);

    props.onValidated({
      EMAIL: email.value,
    });

    auth.restorePasswordRequest({
      variables: {
        email: email.value,
        link: props.stepFromUpdate? 'dashboard/settings' : '',
      },
    }).then((response) => {
      const message = response.data.Users_Restore_Password_Request.status;
      modal.setSuccessNotification({
        modalVisible: true,
        content: message,
      });
    }).catch(error => {
      console.log(error);
    });
  };

  return (
    <form onSubmit={ submitForm }>
      <h4 className={ st`modalTitleSecondary` }>Forgot password</h4>
      <p className={ st`modalText` }>Enter your email and we’ll send you instructions.</p>

      { props.status !== 'success' &&
        <>
          <div className={ st`formRow` }>
            <label className={ st`formRowLabel` } htmlFor="userEmail">Email address</label>
            <input
              className={ st`formInput` }
              ref={ node => (email = node) }
              type="email"
              required
              placeholder="Your email address"
              name="EMAIL"
              id="userEmail"
            />
          </div>

          <div className={ st`formRow formRowAction` }>
            <div>
              { errorMessage &&
                <span className={ st`errorMessage` } dangerouslySetInnerHTML={ { __html: errorMessage } } />
              }
              { successMessage &&
                <span className={ st`successMessage` } dangerouslySetInnerHTML={ { __html: successMessage } } />
              }
            </div>

            <button
              type="submit"
              className={ st`button button--primary buttonWide` }
              onClick={ submitForm }
            >
              Request password reset
            </button>
          </div>
          <div className={ st`formRow formFooter` }>
            <Button variant="text" onClick={ () => { props.stepFromUpdate ? props.setCurrentStep('update') : props.setCurrentStep('login'); } }>
              {props.stepFromUpdate ? 'Back to update' : 'Back to login'}
            </Button>
          </div>
          {/* <div className={ st`formRow formFooter` }>
            <Button variant="text" onClick={ () => props.setCurrentStep('reset') }>To reset form (DEMO)</Button>
          </div> */}
        </>
      }

      { props.status === 'success' &&
        <div className={ st`formRow formRowLast` }>
          <div></div>
        </div>
      }
    </form>
  );
}
