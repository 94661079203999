import { createContext, ReactChild, ReactElement, useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { OVERVIEW_LISTINGS, OVERVIEW_LISTINGS_BY_DATE } from '@queries/listings';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';

interface Props {
  children: ReactChild[]|ReactChild
}

export const AppContext = createContext<any>(null);

export default function CookiesProvider({ children }: Props): ReactElement {
  const router = useRouter();

  // SearchLocation states
  const [ loadingUserLocation, setLoadingUserLocation ] = useState<boolean>(false);
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ isSearching, setIsSearching ] = useState<boolean>(false);
  const [ userLocation, setUserLocation ] = useState(null);
  const [ resultsLocation, setResultsLocation ] = useState<Dict>(null);
  const [ suggestions, setSuggestions ] = useState(null);
  const [ selectedLocation, setSelectedLocation ] = useState<Dict>(null);
  const [ personsAmount, setPersonsAmount ] = useState<number>(1);
  const [ getListings, { loading: getListingsLoading, data: getListingsData } ] = useLazyQuery(OVERVIEW_LISTINGS);
  const [ getListingsByDate, { loading: getListingsByDateLoading, data: getListingsByDateData } ] = useLazyQuery(OVERVIEW_LISTINGS_BY_DATE);
  const [ selectedListing, setSelectedListing ] = useState(null);
  const [ selectedDays, setSelectedDaysInternal ] = useState<Date[]>();

  const [ selectFirstSuggestion, setSelectFirstSuggestion ] = useState(false);

  // Search & results
  const [ watchPostionId, setWatchPositionId ] = useState<number>(null);
  const [ startSearch, setStartSearch ] = useState(false);
  const [ searchResults, setSearchResults ] = useState(null);

  // Listing editor states
  const [ currentListingEdited, setCurrentListingEdited ] = useState(null);
  const [ canSaveListing, setCanSaveListing ] = useState(false);
  const [ canPublishListing, setCanPublishListing ] = useState(false);
  const [ triggerSaveExit, setTriggerSaveExit ] = useState(false);

  // Header styling states
  const [ headerVariant, setHeaderVariant ] = useState<'clear'|'simple'|'search'|'default'>('default');
  const [ currentPath, setCurrentPath ] = useState(router.asPath);

  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);

  if (minDate.getHours() > 11) {
    minDate.setDate(minDate.getDate() + 1);
  }

  const cleanDates = (chosenDays?): string[] => {
    if (!chosenDays || !chosenDays.length || !chosenDays[0]) {
      return;
    }

    if (moment.isDate(chosenDays[0])) {
      return chosenDays.map(day => day.toISOString().split('T')[0]);
    } else {
      return [];
    }
  };

  const [ dates, setDates ] = useState<string[]>(cleanDates());

  useEffect(() => {
    checkPath(false);
  }, [ router.asPath ]);

  const setSelectedDays = (chosenDays) => {
    setDates(cleanDates(chosenDays));
    setSelectedDaysInternal(chosenDays);
  };

  useEffect(() => {
    if (router.query.dates) {
      setSelectedDays([ new Date(router.query.dates as string) ]);
      setPersonsAmount(parseInt(router.query.persons as string));
    }
  }, [ router.query.dates ]);

  const checkPath = (first) => {
    if (currentPath !== router.asPath || first) {
      setCurrentPath(router.asPath);

      if (router.asPath.indexOf('/checkout') === 0
        || router.asPath.indexOf('/dashboard/listings/') === 0) {
        setHeaderVariant('clear');
      } else if (router.asPath.indexOf('/dashboard') === 0) {
        setHeaderVariant('simple');
      } else {
        setHeaderVariant('default');
      }
    }
  };

  // Run once
  useEffect(() => {
    checkPath(true);
  }, []);

  useEffect(() => {
    if (!getListingsLoading && loading) {
      setTimeout(() => setLoading(false), 500);
    }
  }, [ getListingsLoading, loading ]);

  useEffect(() => {
    if (!getListingsByDateLoading && loading) {
      setTimeout(() => setLoading(false), 500);
    }
  }, [ getListingsByDateLoading, loading ]);

  useEffect(() => {
    // Distance is a straight line
    function calculateDistance(lat1, lng1, lat2, lng2) {
      const deltaLat = lat2 - lat1;
      const deltaLng = lng2 - lng1;
      return (deltaLat * deltaLat + deltaLng * deltaLng);
    }

    const searchByDate = (selectedDays && selectedDays[0]);

    const data = searchByDate
      ? getListingsByDateData
      : getListingsData;

    if (data && data.listings[0]) {
      // Quickly filter out listings without a location property
      let foundListings = data.listings.filter(listing => !!(listing.listings_locations && listing.listings_locations[0]));

      const location = resultsLocation.lat && resultsLocation.lng
        ? { ...resultsLocation }
        : { lat: resultsLocation.geocode.geometry.location.lat(), lng: resultsLocation.geocode.geometry.location.lng() };

      foundListings = foundListings
        .map((listing) => {
          const { lat: listingLat, lng: listingLng } = listing.listings_locations[0];
          const listingExtensible = { ...listing }; // Copy to make it extensible

          // Calculate distance to location
          listingExtensible.distance = calculateDistance(location.lat, location.lng, listingLat, listingLng);

          // Have workspace amount calculations ready
          listingExtensible.totalWorkSpaces = listingExtensible.listings_workspaces.reduce((accu, currentValue) => accu + currentValue.amount, 0);
          listingExtensible.availableWorkSpaces = listingExtensible.totalWorkSpaces - listingExtensible.bookings_aggregate.aggregate.sum.persons_amount;

          listingExtensible.listings_availabilities = listingExtensible.listings_availabilities[0] && listingExtensible.listings_availabilities[0].dates
            ? listingExtensible.listings_availabilities[0].dates.filter(date => date >= moment(minDate).format('YYYY-MM-DD'))
            : [];

          listingExtensible.listings_availabilities.sort((a, b) => a < b ? -1 : 1);

          return listingExtensible;
        });

      // If we have a specific location, set/sort listing distance.
      if (searchByDate) {
        foundListings
          .sort((a, b) => {
            return a.distance - b.distance;
          });
      }

      if (!searchByDate) {
        // If we are not searching by date, sort on availability
        foundListings
          .sort((a, b) => {
            if (!a.listings_availabilities[0]) {
              return 1;
            }

            if (!b.listings_availabilities[0]) {
              return -1;
            }

            return a.listings_availabilities[0] < b.listings_availabilities[0] ? -1 : 1;
          });
      }

      // Filter out workspaces that are fully booked or when search params personsAmount is larger than available workspaces
      foundListings = foundListings
        .filter((listing) => {
          return listing.availableWorkSpaces >= personsAmount
                    && listing.bookings_aggregate.aggregate.sum.persons_amount < listing.totalWorkSpaces;
        });

      setSearchResults(foundListings || []);
      return;
    }

    setSearchResults([]);
  }, [ getListingsData, getListingsByDateData ]);

  useEffect(() => {
    if (selectedLocation && selectedLocation.geocodeLoading) {
      return;
    }
  }, [ selectedLocation?.geocodeLoading ]);

  const requestUserLocation = () => {
    if (!userLocation) {
      if (!navigator.geolocation) {
        console.log('Geolocation is not supported by your browser');
      } else {
        setLoadingUserLocation(true);
        setSelectedLocation(null);

        const watchPosId = navigator.geolocation.watchPosition(userLocationCallback, disableUserLocation, {
          enableHighAccuracy: true,
        });

        setWatchPositionId(watchPosId);
      }
    }
  };

  const userLocationCallback = (position) => {
    setLoadingUserLocation(false);

    setUserLocation({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    });
  };

  const disableUserLocation = () => {
    navigator.geolocation.clearWatch(watchPostionId);
    setLoadingUserLocation(false);
    setWatchPositionId(null);
    setUserLocation(null);
  };

  const search = async () => {
    let currentLocation;
    let currentCoords;
    let searchRange = .4;

    if (userLocation) {
      currentCoords = userLocation;
      currentLocation = userLocation;
    } else if (isSearching && suggestions) {
      currentCoords = suggestions[0].geocode?.geometry.locationResolved;
      currentLocation = suggestions[0];
      setSelectFirstSuggestion(suggestions[0]);
    } else if (selectedLocation) {
      currentCoords = await selectedLocation?.geocode?.geometry.locationResolved;
      currentLocation = selectedLocation;
    } else {
      // No location given, so we should provide bounds for the search ourselves
      // Following lat/lng is Utrecht
      currentCoords = {
        lat: 52.092876,
        lng: 5.104480,
      };

      currentLocation = currentCoords;

      // Increase the extra range var so we get large bounds
      searchRange = 2;
    }

    if (!currentCoords) {
      return;
    }

    const locationLatLng = {
      lat: currentCoords.lat,
      lng: currentCoords.lng,
    };

    let bounds: Dict = {
      lat: {
        min: locationLatLng.lat - searchRange,
        max: locationLatLng.lat + searchRange,
      },
      lng: {
        min: locationLatLng.lng - searchRange,
        max: locationLatLng.lng + searchRange,
      },
    };

    const foundBounds = currentLocation?.geocode?.geometry?.bounds;

    if (foundBounds) {
      // We're dealing with city bounds, no need to increase the search range a lot
      searchRange = .1;

      const ne = await foundBounds.getNorthEast();
      const sw = await foundBounds.getSouthWest();

      const neLatLng = {
        lat: await ne.lat(),
        lng: await ne.lng(),
      };

      const swLatLng = {
        lat: await sw.lat(),
        lng: await sw.lng(),
      };

      bounds = {
        lat: {
          min: swLatLng.lat - searchRange,
          max: neLatLng.lat + searchRange,
        },
        lng: {
          min: swLatLng.lng - searchRange,
          max: neLatLng.lng + searchRange,
        },
      };
    }


    setPersonsAmount(personsAmount || 1);

    setResultsLocation(currentLocation);

    setLoading(true);

    if (router.pathname !== '/listings') {
      router.push('/listings');
    }

    const searchByDate = (selectedDays && selectedDays[0]);

    const queryVariables: {
      date?: string[],
      minLat: number,
      maxLat: number,
      minLng: number,
      maxLng: number,
      amount: number,
    } = {
      minLat: bounds.lat.min,
      maxLat: bounds.lat.max,
      minLng: bounds.lng.min,
      maxLng: bounds.lng.max,
      amount: personsAmount || 1,
    };

    if (searchByDate) {
      setSelectedDays(selectedDays);
      const selectedDaysCleaned = selectedDays?.map(day => day.toISOString().split('T')[0]);

      queryVariables.date = selectedDaysCleaned;

      getListingsByDate({
        variables: queryVariables,
      });
    } else {
      getListings({
        variables: queryVariables,
      });
    }

    (document.activeElement as HTMLElement).blur();
  };

  useEffect(() => {
    if (startSearch) {
      search();
      setStartSearch(false);
    }
  }, [ startSearch ]);

  const contextValue = {
    loadingUserLocation,
    setLoadingUserLocation,

    userLocation,
    setUserLocation,

    searchResults,

    selectedLocation,
    setSelectedLocation,

    resultsLocation,
    setResultsLocation,

    watchPostionId,
    setWatchPositionId,

    personsAmount,
    setPersonsAmount,

    dates,
    selectedDays,
    setSelectedDays,

    currentListingEdited,
    setCurrentListingEdited,

    canSaveListing,
    setCanSaveListing,

    canPublishListing,
    setCanPublishListing,

    triggerSaveExit,
    setTriggerSaveExit,

    headerVariant,
    setHeaderVariant,

    disableUserLocation,

    selectFirstSuggestion,
    setSelectFirstSuggestion,

    isSearching,
    setIsSearching,

    suggestions,
    setSuggestions,

    loading,

    searchListings: getListings,

    setStartSearch,

    requestUserLocation,

    setSelectedListing,
    selectedListing,
  };

  return (
    <AppContext.Provider value={ contextValue }>
      { children }
    </AppContext.Provider>
  );
}
