import { ReactElement } from 'react';
import Stl from '@root/lib/Stl';
import quote from './Quote.module.scss';
const st = (new Stl()).bind(quote);

export default function Quote(): ReactElement {
  return (
    <div className={ st`grid` }>
      <div className={ st`image` }>
      </div>
      <div className={ st`content` }>
        <div className={ st`quote` }>
          We are commercial real estate developers that are always looking for new business opportunities.
          We see a new trend in the commercial real estate market which is flexibilization.
          Because we started working with YourDesq we&apos;ve been able to host pay-per-use workspaces,
          without dealing with the operational complexity of bringing the product to the consumer. YourDesq is basically the new AirBnb of workspaces!</div>
        <span className={ st`author` }>Boatex Beheer</span>
      </div>
    </div>
  );
}
