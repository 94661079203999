import { ReactElement,useContext, useEffect, useState } from 'react';
import { AppContext } from '@context/AppContext';
import { useRouter } from 'next/router';
import Stl from '@root/lib/Stl';
import header from './Header.module.scss';
import Link from 'next/link';
import { ModalsContext } from '@root/context/ModalsContext';
const st = (new Stl()).bind(header);

export default function ListingsDetailHeader(): ReactElement {
  const appContext = useContext(AppContext);
  const modalContext = useContext(ModalsContext);
  const router = useRouter();
  const [ listing, setListing ] = useState({
    title: null,
    price: null,
    city: null,
    district: null,
    surface_area: null,
  });

  useEffect(() => {
    const selectedListing = appContext.selectedListing;

    if(selectedListing) {
      const location = selectedListing.listings_locations[0];
      
      setListing({
        title: selectedListing.title,
        price: selectedListing.cheapest_price,
        city: location.city,
        district: location.district,
        surface_area: location.surface_area,
      }); 
    }
  }, [ appContext.selectedListing ]);

  return (
    <div className="container">
      <div className={ st`right listing-detail` }>

        <div>
          <h5>{ listing.title }</h5>
          <p className={ st`location` }>
            { listing.district && listing.district + ', ' }
            { listing.city }
            { listing.surface_area && ', ' + listing.surface_area + 'm²' }
          </p>
        </div>

        <div className={ st`price` }>
          <small>From</small><h5>€{ listing.price }</h5>
        </div>

        <button className="button button--inverted" onClick={ () => modalContext.setModalVisibleShare(true) }>
          <img src="/images/icons/share.svg" />
          <span>Share</span>
        </button>
        <Link href={ `${ router.asPath }#availability` }>
          <button className="button button--primary">Book now</button>
        </Link>
      </div>
    </div>
  );
}
