import { ReactElement } from 'react';
import Stl from '@root/lib/Stl';
import uspcards from './UspCards.module.scss';
const st = (new Stl()).bind(uspcards);

type Props = {
  variant?: 'primary';
  items: any;
  iconSize?: string;
};

export default function UspCards(props: Props): ReactElement {
  return (
    <div className={ st`${ props.variant ? props.variant : 'primary' }` }>
      <div className={ st`container` }>
        <ul className={ st`grid grid--${ props.variant || 'primary' }` }>
          { props.items.map((item: any, index) => (
            <li className={ st`item` } key={ index }>
              { item.counter &&
                <div className={ st`counter ${ item.circle && uspcards.circle }` }>{item.counter}{item.percentage && <span className={ st`percentage` }>%</span>}</div>
              }

              <div className={ st`header` }>
                { item.icon &&
                  <div className={ st`icon` }>
                    <div className="image">
                      <img
                        src={ item.icon }
                        width={ props.iconSize }
                        height={ props.iconSize }
                      />
                    </div>
                  </div>
                }
                <h5 className={ st`heading` }>{ item.heading }</h5>
              </div>

              { item.description &&
                <div className={ st`description` }>
                  <p>{item.description}</p>
                </div>
              }
            </li>
          )) }
        </ul>
      </div>
    </div>
  );
}
