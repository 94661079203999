import { ReactElement, useState } from 'react';
import Stl from '@root/lib/Stl';
import faq from './Faq.module.scss';
const st = (new Stl()).bind(faq);

type Props = {
  question: string;
  answer: string;
}

export default function FaqItem(props: Props): ReactElement {
  const [ isExpanded, setIsExpanded ] = useState(false);

  return (
    <li className={ st`item ${ isExpanded && 'expanded' }` }>
      <button className={ st`button` } onClick={ () => setIsExpanded(!isExpanded) }>
        {props.question}
      </button>
      <div className={ st`wrapper` }>
        <div className={ st`answer` } dangerouslySetInnerHTML={ { __html: props.answer } } />
      </div>
    </li>
  );
}
