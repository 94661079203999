import { ReactElement, useState, useContext } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
// import { Button } from '../..';

import { AuthContext } from '@context/AuthContext';

import Stl from '@root/lib/Stl';
import passwordreset from '../PasswordReset/PasswordReset.module.scss';
import { ModalsContext } from '@root/context/ModalsContext';
const st = (new Stl()).bind(passwordreset);

type Props = {
  status: string;
  onValidated: UnknownFunc;
  setCurrentStep: UnknownFunc;
  setStepFromUpdate: UnknownFunc;
  closeModal: UnknownFunc;
}

export default function FormPasswordUpdate(props: Props): ReactElement {
  const auth = useContext(AuthContext);
  const modal = useContext(ModalsContext);
  
  // Create reference variables for input fields
  const [ password, setPassword ] = useState('');
  const [ passwordConfirm, setPasswordConfirm ] = useState('');
  const [ newPassword, setNewPassword ] = useState('');

  const [ errorMessage, setErrorMessage ] = useState(null);
  const [ successMessage, setSuccessMessage ] = useState(null);

  if (props.status === 'error') {
    setErrorMessage('');
  }

  if (props.status === 'success') {
    setSuccessMessage('');
  }

  const submitForm = async (event) => {
    event.preventDefault();
    
    if (!password || !newPassword || !passwordConfirm) {
      setErrorMessage('Please fill in all fields.');
      return;
    }

    if (newPassword === password) {
      setErrorMessage('New and old passwords must not match');
      return;
    }
    if (newPassword !== passwordConfirm) {
      setErrorMessage('New password and confirmation must match.');
      return;
    }

    setErrorMessage(null);
    setSuccessMessage(null);


    props.onValidated({
      PASSWORD: password,
      PASSWORDCONFIRM: passwordConfirm,
      NEWPASSWORD: newPassword,
    });
    
    const body = {
      uuid: auth.account.uuid,
      currentPassword: password,
      newPassword: newPassword,
    };

    const response = await fetch(`${ process.env.NEXT_PUBLIC_HOST_MIDDLEWARE }/users/password_update`, {
      method: 'post',
      body: JSON.stringify(body),
    });
    const status = await response.json();

    if (!response.ok) {
      setErrorMessage(status.message);
      return;
    }
    
    modal.setSuccessNotification({ modalVisible: true, content: null });
    props.closeModal();
    setPassword('');
    setPasswordConfirm('');
    setNewPassword('');
  };

  return (
    <form onSubmit={ submitForm }>
      <h4 className={ st`modalTitleSecondary` }>Update password</h4>

      { props.status !== 'success' &&
        <>
          <div className={ st`formRow` }>
            <label className={ st`formRowLabel` } htmlFor="currentPassword">
              <span>Current password</span>
              <a onClick={ () => {
                props.setStepFromUpdate(true);
                props.setCurrentStep('reset-request');
              } }>Forgot your password?</a>
            </label>
            <input
              className={ st`formInput` }
              type="password"
              name="CURRENTPASSWORD"
              id="currentPassword"
              value={ password }
              onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value) }
            />
          </div>


          <div className={ st`formRow` }>
            <label className={ st`formRowLabel` } htmlFor="newPassword">New password</label>
            <input
              className={ st`formInput` }
              type="password"
              name="NEWPASSWORD"
              id="newPassword"
              value={ newPassword }
              onChange={ (event: any) => setNewPassword(event.target.value) }
            />
          </div>

          <div className={ st`formRow` }>
            <label className={ st`formRowLabel` } htmlFor="userPasswordConfirm">Confirm new password</label>
            <input
              className={ st`formInput` }
              type="password"
              name="PASSWORDCONFIRM"
              id="userPasswordConfirm"
              value={ passwordConfirm }
              onChange={ (event: any) => setPasswordConfirm(event.target.value) }
            />
            <PasswordStrengthBar className={ st`strengthBar` } password={ passwordConfirm } />
          </div>

          <div className={ st`formRow formRowAction` }>
            <div>
              {errorMessage &&
                <span className={ st`errorMessage` } dangerouslySetInnerHTML={ { __html: errorMessage } } />
              }
              {successMessage &&
                <span className={ st`successMessage` } dangerouslySetInnerHTML={ { __html: successMessage } } />
              }
            </div>

            <button
              type="submit"
              className={ st`button button--primary buttonWide` }
              onClick={ submitForm }
            >
              Update password
            </button>
          </div>
        </>
      }

      { props.status === 'success' &&
        <div className={ st`formRow formRowLast` }>
          <div></div>
        </div>
      }
    </form>
  );
}
