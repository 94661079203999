import { ReactElement, useContext, useState, useEffect } from 'react';
import { AppContext } from '@context/AppContext';
import Button from '@app/Button/Button';
import AutoComplete from '@app/Header/AutoComplete';
import Persons from '@app/Form/Persons';
import FormDaypicker from '@app/Form/Daypicker';
import { useRouter } from 'next/router';

import Stl from '@root/lib/Stl';
import locationSearch from './LocationSearch.module.scss';
const st = (new Stl()).bind(locationSearch);

type Props = {
  wrappable?: boolean;
  setPersonsAmount: UnknownFunc;
  setSelectedDays: UnknownFunc;
  personsAmount: number;
  handleDayClick: UnknownFunc;
  selectedDays: Date[];
  onSubmit: UnknownFunc;
  setExpanded: UnknownFunc;
  expanded: boolean;
}

const steps = [ 'city', 'persons', 'date' ];

export default function LocationSearchMobile(props: Props): ReactElement {
  const appContext = useContext(AppContext);
  const router = useRouter();

  const [ step, setStep ] = useState('city');
  const [ preventAutoChangeStep, setPreventAutoChangeStep ] = useState(false);

  // fix open persons step after searching
  useEffect(() => {
    setPreventAutoChangeStep(true);
  }, [ router.route ]);

  useEffect(() => {
    if(!props.expanded) {
      setStep('city');
    }
  }, [ props.expanded ]);

  useEffect(() => {
    if(step==='city' && (appContext.selectedLocation || appContext.userLocation) && !preventAutoChangeStep) {
      setStep('persons');
      return;
    }

    if (!appContext.userLocation) {
      setPreventAutoChangeStep(false);
    }
  }, [ appContext.selectedLocation, appContext.userLocation ]);

  const changeStep = (action: 'next' | 'prev', event?) => {
    const index = steps.indexOf(step);

    if (action === 'next') {
      if (step === 'date') {
        search(event);
        return;
      }
      setStep(steps[index+1]);
      return;
    }
    if (step === 'persons' && appContext.userLocation) {
      setPreventAutoChangeStep(true);
    }
    setStep(steps[index-1]);
  };

  const search = (event) => {
    setStep('city');
    props.onSubmit(event);
  };

  const renderNavigation = () => {
    if (step === 'date') {
      return (
        <div className={ st`navigation` }>
          {props.selectedDays
            ? (
              <Button
                variant='transparent'
                onClick={ () => props.setSelectedDays(null) }
                className='without-border'
              >
                Clear
              </Button>
            )
            : (
              <Button
                variant='transparent'
                onClick={ (event) => changeStep('next', event) }
                className='without-border'
              >
                Skip
              </Button>
            )
          }
          <Button
            disabled={ appContext.loading || !props.selectedDays }
            onClick={ (event)=>  search(event) }
          >
            Search
          </Button>
        </div>
      );
    }
    if (step === 'persons') {
      return (
        <div className={ st`navigation` }>
          {props.personsAmount
            ? (
              <Button
                variant='transparent'
                onClick={ () => props.setPersonsAmount(0) }
                className='without-border'>
                Clear
              </Button>
            ): (
              <Button
                variant='transparent'
                onClick={ (event) => changeStep('next', event) }
                className='without-border'
              >
                Skip
              </Button>
            )
          }
          <Button
            onClick={ () => changeStep('next') }
            disabled={ !props.personsAmount }
          >
            Next
          </Button>
        </div>
      );
    }
  };

  const stepHeader = (
    <div className={ st`header` }>
      <Button
        variant="transparent"
        onClick={ (event) => changeStep('prev', event) }
      >
        <img src='/images/icons/chevron-left.svg' />
      </Button>
      <span>
        {
          step === 'persons'
            ? 'Number of people'
            : 'Choose your date'
        }
      </span>
    </div>
  );
  return (
    <>
      {step == 'city' &&
      <AutoComplete
        placeholder='Where do you want to work?'
        variant={ props.wrappable ? 'secondary' : 'tertiary' }
        onFocus={ props.setExpanded }
        searchIcon={ !props.expanded }
        hideDropdown={ props.expanded }
      />
      }
      {step == 'persons' &&
      <>
        {stepHeader}
        <Persons
          setPersonsAmount={ props.setPersonsAmount }
          personsAmount={ props.personsAmount }
          variant={ props.wrappable ? 'secondary' : 'tertiary' }
          onlyDropdownShow
        />
      </>
      }
      {step == 'date' &&
      <>
        {stepHeader}
        <FormDaypicker
          selectedDays={ props.selectedDays }
          onDayClick={ props.handleDayClick }
          variant={ props.wrappable ? 'secondary' : 'tertiary' }
          onlyDropdownShow
        />
      </>
      }
      {renderNavigation()}
    </>
  );
}
