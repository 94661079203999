import React from 'react';
import { CSSTransition } from 'react-transition-group';
import style from './Transition.module.scss';

interface ITransitionProps {
  appear?: boolean
  in: boolean
  type: string
  timeout?: number
  unmountOnExit?: boolean
  children: React.ReactNode
}

export default function Transition(props: ITransitionProps): React.ReactElement {
  return (
    <CSSTransition
      in={ props.in }
      timeout={ props.timeout || 300 }
      unmountOnExit
      classNames={ {
        enterActive: style[`${ props.type }-enter`],
        enterDone: style[`${ props.type }-enter-active`],
        exitActive: style[`${ props.type }-exit`],
        exitDone: style[`${ props.type }-exit-active`],
      } }>
      { props.children }
    </CSSTransition>
  );
}
